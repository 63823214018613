import React from "react";
import GallerySection from "../../pagesComponents/gallary/gallarySection/GallerySection";
import NavbarTwo from "../../components/navbar/NavbarTwo";
import Slider from "../../pagesComponents/gallary/slider/Slider";
import { useDocumentTitle } from "../../useDocumentTitle";

const Gallery = () => {
  useDocumentTitle("Gallery")

  return (
    <div>
      <NavbarTwo />
      <Slider/>
      <GallerySection />
    </div>
  );
};

export default Gallery;

import React from "react";
import "./slider.css";
const Slider = () => {
  return (
    <section className="bg_image_3">
    <div className="content">
      <h2>Gallery</h2>
    
    </div>
  </section>
  );
};

export default Slider;

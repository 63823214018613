import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Features from "../../components/features/Features";
import About from "../../components/about/About";
import Contact from "../../components/contactUs/Contact";
import Service from "../../components/service/Service";
import Counter from "../../components/counter/Counter";
import Blog from "../../components/blog/Blog";
import Testimonial from "../../components/testimonials/Testimonial";
import Events from "../../components/events/Events";
import { useDocumentTitle } from "../../useDocumentTitle";

const Home = ({ type }) => {
  useDocumentTitle("Home")
  return (
    <div>
      {/* <Slider /> */}
      <Navbar/>
      <Features />
      <About />
      <Contact />
      {/* <ServicesTwo/> */}
      <Service />
      <Counter />
      {/* <Portfolio /> */}
      <Events />
      <Testimonial />
      <Blog type="home" />
    </div>
  );
};

export default Home;

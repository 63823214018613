import React from "react";
import "./publications.css";
import { Link } from "react-router-dom";
const PublicationsCards = () => {
  return (
    <>
      <section id="blog-area" className="blog-default-area">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 shadow-sm p-3 mb-5 bg-body rounded">
              <Link to="/publication/1">
                <div className="single-blog-colum-style1">
                  <div
                    className="single-blog-post style3 wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="img-holder">
                      <img
                        src="/images/functions/functions.jpeg"
                        alt="Awesome Image"
                      />
                      <div className="overlay-style-two"></div>
                      <div className="overlay">
                        <div className="box">
                          <div className="post-date">
                            <h3>
                              Mar <br />
                              <span>02</span>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-holder">
                      <h3 className="blog-title">
                        <a href="blog-single.html">
                          Best low-cost interior designing ideas for your room
                        </a>
                      </h3>

                      <div className="text">
                        <p>
                          Same as saying through shrinking from toil and pain
                          these cases perfectly simple...
                        </p>
                        <Link to={`/publication/1`}>
                          <a
                            className="btn-two custom-publication-button"
                            href="blog-single.html"
                          >
                            Read More<span className="flaticon-next "></span>
                          </a>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 shadow-sm p-3 mb-5 bg-body rounded">
              <Link to="/publication/1">
                <div className="single-blog-colum-style1">
                  <div
                    className="single-blog-post style3 wow fadeInLeft"
                    data-wow-delay="100ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="img-holder">
                      <img
                        src="/images/functions/functions2.jpeg"
                        alt="Awesome Image"
                      />
                      <div className="overlay-style-two"></div>
                      <div className="overlay">
                        <div className="box">
                          <div className="post-date">
                            <h3>
                              Feb <br />
                              <span>14</span>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-holder">
                      <h3 className="blog-title">
                        <a href="blog-single.html">
                          Wondering if interior design is a dying filed? Read
                          this
                        </a>
                      </h3>

                      <div className="text">
                        <p>
                          When our power of choice is untrammelled and nothing
                          prevents our being able...
                        </p>
                        <a
                          className="btn-two custom-publication-button"
                          href="blog-single.html"
                        >
                          Read More<span className="flaticon-next "></span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 shadow-sm p-3 mb-5 bg-body rounded">
              <Link to="/publication/1">
                <div className="single-blog-colum-style1">
                  <div
                    className="single-blog-post style3 wow fadeInLeft"
                    data-wow-delay="200ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="img-holder">
                      <img src="/images/gym/gym2.jpeg" alt="Awesome Image" />
                      <div className="overlay-style-two"></div>
                      <div className="overlay">
                        <div className="box">
                          <div className="post-date">
                            <h3>
                              Jan <br />
                              <span>31</span>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-holder">
                      <h3 className="blog-title">
                        <a href="blog-single.html">
                          Creating drama and feeling with a tiled bed wall
                        </a>
                      </h3>
                   
                      <div className="text">
                        <p>
                          Certain circumstances and owing to the claims of duty
                          or the obligations of business...
                        </p>
                        <a
                          className="btn-two custom-publication-button"
                          href="blog-single.html"
                        >
                          Read More<span className="flaticon-next "></span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 shadow-sm p-3 mb-5 bg-body rounded">
              <Link to="/publication/1">
                <div className="single-blog-colum-style1">
                  <div
                    className="single-blog-post style3 wow fadeInLeft"
                    data-wow-delay="300ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="img-holder">
                      <img
                        src="/images/sports/sport1.jpeg"
                        alt="Awesome Image"
                      />
                      <div className="overlay-style-two"></div>
                      <div className="overlay">
                        <div className="box">
                          <div className="post-date">
                            <h3>
                              Jan <br />
                              <span>16</span>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-holder">
                      <h3 className="blog-title">
                        <a href="blog-single.html">
                          Creating drama and feeling with a tiled bed wall
                        </a>
                      </h3>

                      <div className="text">
                        <p>
                          Certain circumstances and owing to the claims of duty
                          or the obligations of business...
                        </p>
                        <a
                          className="btn-two custom-publication-button"
                          href="blog-single.html"
                        >
                          Read More<span className="flaticon-next "></span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PublicationsCards;

import React from "react";
import { Link } from "react-router-dom";

const NavbarTwo = () => {
  return (
    <div className="justify-content-center header_new header-transparent">
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container">
          <Link className="navbar-brand logo" to="/">
            <img src="https://i.ibb.co/1scRhkY/gymkhana-logo.png" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav  me-auto mb-2 mb-lg-0 ms-auto">
              <li className="nav-item">
                <Link to="/">
                  <a className="nav-link" href="#">
                    Home
                  </a>
                </Link>{" "}
              </li>
              <li className="nav-item">
                <Link to="/gallery">
                  <a className="nav-link" href="#">
                    Gallery
                  </a>
                </Link>
              </li>

              <li className="nav-item">
                <Link to="/booking-form">
                  <a className="nav-link" href="">
                    Booking
                  </a>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/about">
                  <a className="nav-link" href="">
                    About
                  </a>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/board-of-governors">
                  <a className="nav-link" href="">
                    Board
                  </a>
                </Link>
              </li>
              <li className="nav-item">
                      <Link to="/all-events">
                        <a className="nav-link" href="#">
                        Events
                         </a>
                      </Link>
                    </li>
              <li className="nav-item">
                <Link to="/contact">
                  <a className="nav-link" href="#">
                    Contact
                  </a>
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <form className="myfrm d-flex">
          <div className="srch-ic d-flex">
            <i className="fas fa-search s-icon"></i>
          </div>
          <div className="main-menu-two__btn-box">
            <a className="thm-btn main-menu-two__btn" href="#">
              My Gymkhana
            </a>
          </div>
        </form>
      </nav>
    </div>
  );
};

export default NavbarTwo;

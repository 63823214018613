import React from "react";
import "../personDetailScreen.css";
import NavbarTwo from "../../../components/navbar/NavbarTwo";

const AbbasMeikan = () => {

  return (
    <>
      <NavbarTwo />
      <div className="profil-area section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 my-4 shadow-sm">
              <div className="profile-name-img-area text-center box-shadow2 border-top2 section-background">
                <div className="prfile-img ">
                  <img
                    src="/facility/facal3.jpeg"
                    alt="profile"
                    className="prfile-img img-responsive"
                  />
                </div>
                <div className="profile-name text-center">
                  <h1>Sardar Fakhar Abbas Meikan,
</h1>
                  <h5 className="cd-headline letters scale">
                  Member Board of Governors
                  </h5>
                  {/* <div className="social_profile">
                  <ul className="social-sec">
                    <li>
                      <a
                        href="https://www.facebook.com/naeemlazy?mibextid=ZbWKwL"
                        target="_blank"
                      >
                        <span>
                          <i className="fab fa-facebook-f fa-2x"></i>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/SheikhNaeemLazy?t=qdaTqh4oPJd-6CUDCKCi3A&amp;s=09"
                        target="_blank"
                      >
                        <span>
                          <i className="fab fa-twitter fa-2x"></i>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.tiktok.com/@naeemsheikh257?_t=8YZi7iYyZb0&amp;_r=1"
                        target="_blank"
                        >
                        <span>
                          <i className="fab fa-tiktok fa-2x"></i>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://instagram.com/lazysheikh?igshid=NDk5N2NlZjQ="
                        target="_blank"
                      >
                        <span>
                          <i className="fab fa-instagram fa-2x"></i>
                        </span>
                      </a>
                    </li>

                    <li>
                      <a
                        href="https://youtube.com/@thesheikhsquare3863"
                        target="_blank"
                        >
                        <span>
                          <i className="fab fa-youtube fa-2x"></i>
                        </span>
                      </a>
                    </li>
                  </ul>
                </div> */}
                </div>
              </div>
            </div>
            <div className="col-md-6 my-4 shadow-sm">
              <div className="profile box-shadow2 border-top2 section-background">
                <div className="section-title">
                  <h3>Profile</h3>
                </div>
                <div className="profile-info table-responsive">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <i className="fa fa-user"></i>
                        </td>
                        <td>
                          <span>Name</span>
                        </td>
                        <td>
                          <b>:</b>
                        </td>
                        <td>Abbas Meikan</td>
                      </tr>
                      <tr>
                        <td>
                          <i className="fa fa-calendar"></i>
                        </td>
                        <td>
                          <span>Date of Birth</span>
                        </td>
                        <td>
                          <b>:</b>
                        </td>
                        <td>10/02/1993</td>
                      </tr>
                      <tr>
                        <td>
                          <i className="fa fa-map-marker-alt"></i>
                        </td>
                        <td>
                          <span>Address</span>
                        </td>
                        <td>
                          <b>:</b>
                        </td>
                        <td>Shop # 13-14 Gulbergcity</td>
                      </tr>
                      <tr>
                        <td>
                          <i className="fab fa-whatsapp-square"></i>
                        </td>
                        <td>
                          <span>Whatsapp</span>
                        </td>
                        <td>
                          <b>:</b>
                        </td>
                        <td>000000000</td>
                      </tr>
                      <tr>
                        <td>
                          <i className="fa fa-envelope"></i>
                        </td>
                        <td>
                          <span>Email</span>
                        </td>
                        <td>
                          {" "}
                          <b>:</b>
                        </td>
                        <td>example@gmail.com</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="objective-area box-shadow2 border-top2 section-background color">
                <div className="section-title">
                  <h3>Message</h3>
                </div>
                <div className="objective-content">
                  <p>
                  As a Member of the Board of Governors, Sardar Fakhar Abbas Meikan plays a pivotal role in guiding the organization towards achieving its objectives and ensuring its long-term sustainability. His contributions, both in terms of governance and strategy, have been instrumental in the success and growth of the organization.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AbbasMeikan;

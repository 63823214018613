import React from "react";
import "./buttonComponent.css";
import { Link } from "react-router-dom";
const ButtonComponent = ({ title }) => {
  return (
    <Link to="/booking-form">
      <button type="submit" class="button_component">
    {title}
      </button>
    </Link>
  );
};

export default ButtonComponent;

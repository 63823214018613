import React from "react";
import "./service.css";
const Service = () => {
  return (
    <section className="service py-5 mt-5">
      <div className="container">
        <div className="row pb-2">
          <div className="col-xl-6">
            <div className="services-two__left">
              <div className="section-title text-left">
                <div className="section-title__icon mb-2">
                  <span className="fa fa-star"></span>
                </div>
                <span className="section-title__tagline">Our facilities</span>
                <h2 className="section-title__title">
                  Explore online Services
                  <br /> &amp; Facilities
                </h2>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="services-two__right">
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-4 custom-class-responsive">
                  <div className="services-two__single">
                    <h3 className="services-two__title">
                      <a href="/">
                        All
                        <br /> Sports
                      </a>
                    </h3>
                    <div className="services-two__icon">
                      <span className="icon-accept">
                        <i className="far fa-check-circle"></i>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-4 custom-class-responsive">
                  <div className="services-two__single">
                    <h3 className="services-two__title">
                      <a href="/">
                        Gymkhana
                        <br /> Library
                      </a>
                    </h3>
                    <div className="services-two__icon">
                      <span className="icon-accept">
                        <i className="far fa-check-circle"></i>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-4 custom-class-responsive">
                  <div className="services-two__single">
                    <h3 className="services-two__title">
                      <a href="/">
                        {" "}
                        Guest
                        <br />
                        Room
                      </a>
                    </h3>
                    <div className="services-two__icon">
                      <span className="icon-accept">
                        <i className="far fa-check-circle"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-2 col-lg-4 col-md-4 custom-class-responsive">
            <div className="services-two__single">
              <h3 className="services-two__title">
                <a href="/">
                  {" "}
                  Food &
                  <br /> Beverages
                </a>
              </h3>
              <div className="services-two__icon">
                <span className="icon-accept">
                  <i className="far fa-check-circle"></i>
                </span>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-lg-4 col-md-4 custom-class-responsive">
            <div className="services-two__single">
              <h3 className="services-two__title">
                <a href="/">
                  Room <br /> Reservation
                </a>
              </h3>
              <div className="services-two__icon">
                <span className="icon-accept">
                  <i className="far fa-check-circle"></i>
                </span>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-lg-4 col-md-4 custom-class-responsive">
            <div className="services-two__single">
              <h3 className="services-two__title">
                <a href="/">
                  {" "}
                  Coffee
                  <br /> Shop
                </a>
              </h3>
              <div className="services-two__icon">
                <span className="icon-accept">
                  <i className="far fa-check-circle"></i>
                </span>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-lg-4 col-md-4 custom-class-responsive">
            <div className="services-two__single">
              <h3 className="services-two__title">
                <a href="/">
                  {" "}
                  Cafe
                  <br /> Green
                </a>
              </h3>
              <div className="services-two__icon custom-class-responsive">
                <span className="icon-accept">
                  <i className="far fa-check-circle"></i>
                </span>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-lg-4 col-md-4 custom-class-responsive">
            <div className="services-two__single">
              <h3 className="services-two__title">
                <a href="/">
                  Fine
                  <br /> Dine
                </a>
              </h3>
              <div className="services-two__icon">
                <span className="icon-accept">
                  <i className="far fa-check-circle"></i>
                </span>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-lg-4 col-md-4 custom-class-responsive">
            <div className="services-two__single">
              <h3 className="services-two__title">
                <a href="/">
                  Roof
                  <br /> Top
                </a>
              </h3>
              <div className="services-two__icon">
                <span className="icon-accept">
                  <i className="far fa-check-circle"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Service;




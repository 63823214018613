import React from 'react'

const Testimonial = () => {
  return (
    <section className="testimoial py-5">
    <div className="testimonails-bg"></div>
    <div className="container">
       <div className="section-title text-center">
          <div className="section-title__icon">
             <span className="fa fa-star"></span>
          </div>
          <span className="section-title__tagline">Our testimonials</span>
          <h2 className="section-title__title">People are Appreciating
             <br/> Sargodha Gymkhana
          </h2>
       </div>
       <div className="row">
          <div className="col-lg-4">
             <div className="testimonial-two__single">
                <div className="testimonial-two__img">
                   <img src="img/gondal.jpg" alt=""/>
                </div>
                <p className="testimonial-two__text">This is due to their excellent service, competitive
                   pricing and customer support. It’s throughly refresing to get such a personal touch.
                </p>
                <h3 className="testimonial-two__client-name">Farwah Gondal</h3>
                <p className="testimonial-two__client-sub-title">Principal college</p>
             </div>
          </div>
          <div className="col-lg-4">
             <div className="testimonial-two__single">
                <div className="testimonial-two__img">
                   <img src="img/imran.jpg" alt=""/>
                </div>
                <p className="testimonial-two__text">This is due to their excellent service, competitive
                   pricing and customer support. It’s throughly refresing to get such a personal touch.
                </p>
                <h3 className="testimonial-two__client-name">Imran Ali </h3>
                <p className="testimonial-two__client-sub-title">Businessman</p>
             </div>
          </div>
          <div className="col-lg-4">
             <div className="testimonial-two__single">
                <div className="testimonial-two__img">
                   <img src="img/khan.jpg" alt=""/>
                </div>
                <p className="testimonial-two__text">This is due to their excellent service, competitive
                   pricing and customer support. It’s throughly refresing to get such a personal touch.
                </p>
                <h3 className="testimonial-two__client-name">Muhammad Khan</h3>
                <p className="testimonial-two__client-sub-title">IT Head</p>
             </div>
          </div>
       </div>
    </div>
 </section>
  )
}

export default Testimonial

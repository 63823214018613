import React from "react";
import "./cinema.css";
import NavbarTwo from "../../../components/navbar/NavbarTwo";
const Cinema = () => {
  return (
    <>
      <NavbarTwo />
      <div className="cinema_container">
        <div className="cinema_wrapper">
          <img
            src="/images/sports/sport4.jpeg"
            alt=""
            className="picture_images"
          />
             </div>
          <div className="once_description">
            <h1>
              Exploring the World of Sports: The Enthralling Journey of Gymkhana
            </h1>
            <p>
              Certainly! Gymkhana is a term often associated with sports and
              equestrian events, particularly in the context of horseback
              riding. It involves a series of competitive activities that
              showcase the skills and agility of both riders and horses. The
              term has also been extended to other sports and recreational
              activities, such as motorsports and even fitness.
              <br />
              In equestrian gymkhana, riders participate in a variety of timed
              events, aiming to complete them as quickly and accurately as
              possible. Some common gymkhana events include barrel racing, pole
              bending, flag racing, keyhole race, and stake racing. These
              activities require riders to navigate their horses through a set
              course, demonstrating their control and precision. Gymkhana
              competitions are not just about speed but also about the harmony
              between the rider and the horse.
              <br />
              <br />
              Barrel racing is a popular gymkhana event that involves racing
              around a series of barrels arranged in a cloverleaf pattern.
              Riders must guide their horses around the barrels without knocking
              them over and then sprint back to the finish line. Pole bending
              requires riders to weave their horses through a line of closely
              spaced poles without touching or knocking any of them. Flag racing
              involves grabbing a flag from a bucket and then returning it to
              another bucket, all while riding at high speed.
            </p>
            <img
              src="/images/sports/sport3.jpeg"
              alt=""
              className="image_sub"
            />

            <p>
              The keyhole race is a test of precision, as riders must guide
              their horses into a tight keyhole-shaped pattern and then back out
              again as quickly as possible. Stake racing involves circling a
              series of stakes in a specific order, showcasing the horse's
              agility and the rider's ability to guide them accurately.
              <br />
              In addition to equestrian gymkhana, there are also motorsport
              variations like autocross gymkhana and rally gymkhana. Autocross
              gymkhana is a motorsport event where drivers navigate through a
              course marked by cones and other obstacles, with the goal of
              achieving the fastest time. It tests a driver's skill in handling
              their vehicle with precision and speed. Rally gymkhana is similar
              but often takes place on mixed surfaces, including dirt and
              gravel, adding an extra layer of challenge to the competition.
              <br />
              <br />
              Furthermore, gymkhana has found a place in the fitness world. Gymkhana-inspired fitness classes and workouts aim to challenge participants with a variety of exercises that require agility, balance, speed, and coordination. These classes often incorporate elements from traditional gymkhana events, such as weaving between cones, jumping hurdles,
            </p>
            <img
              src="/images/sports/sport5.jpeg"
              alt=""
              className="image_sub"
            />
          </div>
     
      </div>
    </>
  );
};

export default Cinema;

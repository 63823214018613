export const data = [
  {
    id: "1",
    image:"https://i.ibb.co/gmVTRY8/Untitled-design-3.png",
    // image:"/facility/facal5.jpeg",
    name:"Mohammad Mohsin Sallahuddin",
    link:"/MuhSallahudin",
    post:"Member Board of Governors"
  },
  {
    id: "2",
    image: "https://i.ibb.co/kMcp1tr/Untitled-design-1.png", 
    // image: "/facility/facal6.jpeg", 
      name:"Ahmed Yar Chadhar",
      link:"/AhmedYarChadhar",
      post:"Additional Deputy Commissioner"
  },
  {
    id: "3",
    image: "/facility/facal4.jpeg", 
      name:"Capt Retd Shoaib Ali",
      link:"/Capt-Retd-Shoaib-Ali",
      post:"Deputy Commissioner Sargodha  "
  },
  {
    id: "4",
    image: "/facility/facal3.jpeg", 
      name:"Sardar Fakhar Abbas Meikan",
      link:"/Abbas-Meikan",
      post:"Additional Deputy Commissioner"
  },
  {
    id: "5",
    image: "/facility/facal1.jpeg", 
      name:"shahraiz Shafi Warriach", 
       link:"/Shafi-Warriach",
       post:"Member Board of Governors"
  },
  {
    id: "6",
    image: "/facility/facality7.jpeg", 
      name:"Muhammad Shoaib Nissowana",
      link:"/Nissowana",
      post:"Member of the Board of Governors"
  },
];

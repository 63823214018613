import { Link } from '@reach/router'
import React from 'react'

const About = () => {
  return (
    <section className="about-two position-relative" id="about">
    <div className="about-two__shape-3 float-bob-x"></div>
    <div className="img-s img-bounce">
       <img src="img/eagle.png" alt=""/>
    </div>
    <div className="container">
       <div className="row">
          <div className="col-lg-6 col-sm-12 ">
             <div className="about-two__left">
                <div className="about-two__img-box">
                   <div className="about-two__img">
                      <img src="img/sargodha_gymkhana_club_6.jpg" alt=""/>
                   </div>
                   <div className="about-two__img-2">
                      <img src="img/sargodha_gymkhana_club_2.jpg" alt=""/>
                   </div>
                   <div className="about-two__shape-1"></div>
                   <div className="about-two__shape-2 img-bounce">
                      <img src="img/star.png" alt=""/>
                   </div>
                </div>
             </div>
          </div>
          <div className="col-lg-6 col-sm-12">
             <div className="about-two__right">
                <div className="section-title text-left">
                   <div className="section-title__icon">
                      <span className="fa fa-star"></span>
                   </div>
                   <span className="section-title__tagline">Welcome to gymkhana</span>
                   <h2 className="section-title__title pt-3">GYMKHANA CLUB SARGODHA
                   </h2>
                </div>
                <p className="about-two__text-2 my-sm-1">
                   Unique amongst the clubs in the city, each of the rooms and suites at the Sargodha Gymkhana reflects its core values of exceptional service standards, exemplary hospitality and homely comfort with the most marginal rates.
                </p>
                <p className="about-two__text-2 my-sm-1">
                   Spacious, luxurious and elegant, all rooms are air conditioned, with international direct dialing, internet and fax facilities, complimentary newspaper, remote-controlled latest LED TV sets and a Mini Bar.
                </p>
                <p className="about-two__text-2 my-sm-1">
                   With panoramic views of the Golf Course or landscaped gardens, each room affords an understated palette of subtle colors and contemporary design. Finest fabrics, modern art and latest technologies assure not just a stay but an experience that guarantees a revisit.
                </p>
                
                <div className="about_btn mt-4">
                   <div className="row">
                      <div className="about_button  col-6">
                         <div className="tm-sc-button btn-view-details ">
                            <a href="/about" className="thm-btn main-menu-two__btn">
                            {/* <span> */}
                            Discover More    
                             {/* </span> */}
                            </a>
                         </div>
                      </div>
                      <div className="about_phone col-6">
                         <div className="icon-box-wrapper">
                            <div className="icon-wrapper">
                               <a className="icon_new" target="_blank" href="mailto:needhelp@domain.com">
                               <i aria-hidden="true" className=" fa fa-mobile-alt"></i>    </a>
                            </div>
                            <div className="icon-text">
                               <div className="content">
                                  <p>Call Us Anytime</p>
                               </div>
                               <h6 className="icon-box-title ">
                                  <a href="tel:+923336752906"> +92 333 6752906 </a>
                                               
                               </h6>
                            </div>
                            <div className="clearfix"></div>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </div>
 </section>
  )
}

export default About

import React from "react";
import Blog from "../../components/blog/Blog";
import { useDocumentTitle } from "../../useDocumentTitle";

const Blogs = () => {
  useDocumentTitle("Blogs")

  return (
    <div>
      <Blog blogPage="blogPage" />
    </div>
  );
};

export default Blogs;

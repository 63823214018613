import React from "react";
import NavbarTwo from "../navbar/NavbarTwo";
import { Link } from "react-router-dom";

const Blog = ({ blogPage }) => {
  return (
    <>
      <NavbarTwo />
      <section>
        <div className="container py-5">
          <div className="section-title text-center">
            <div className="section-title__icon">
              <span className="fa fa-star"></span>
            </div>
            <span className="section-title__tagline">Latest News</span>
            <h2 className="section-title__title">
              Latest news &amp; articles
              <br /> from the blog
            </h2>
          </div>
          <div className="row">
            <div
              className="col-xl-6 col-lg-6 wow fadeInUp animated"
              data-wow-delay="100ms"
            >
              <Link to="/blogs/1">
                <div className="news-two__single">
                  <div className="news-two__img-box">
                    <div className="news-two__img">
                      <img src="img/blog.jpg" alt="" />
                    </div>
                    <div className="news-two__date">
                      <p>
                        30 <br /> JAN
                      </p>
                    </div>
                  </div>
                  <div className="news-two__content">
                    <div className="news-two__user-and-meta">
                      <div className="news-two__user">
                        <div className="news-two__user-img">
                          <img src="img/news-two-user-img.jpg" alt="" />
                        </div>
                        <div className="news-two__user-text">
                          <p>
                            by <br />
                            Admin
                          </p>
                        </div>
                      </div>
                      <div className="news-two__meta">
                        <div className="icon">
                          <span className="fas fa-comments"></span>
                        </div>
                        <div className="text">
                          <p>2 Comments</p>
                        </div>
                      </div>
                    </div>
                    <h3 className="news-two__title">
                      <a href="news-details.html">
                        Sargodha Gymkhana Workout Club
                      </a>
                    </h3>
                    <p className="news-two__text">
                      There are many variations of passages of available but the
                      majority.
                    </p>
                    <div className="news-two__btn">
                      <Link to="/blogs/1">
                        <a href="news-details.html">
                          Read More
                          <i className="fas fa-long-arrow-alt-right"></i>
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div
              className="col-xl-6 col-lg-6 wow fadeInUp animated"
              data-wow-delay="200ms"
            >
              <Link to="/blogs/1">
                <div className="news-two__single">
                  <div className="news-two__img-box">
                    <div className="news-two__img">
                      <img src="img/blog1.jpg" alt="" />
                    </div>
                    <div className="news-two__date">
                      <p>
                        30 <br /> JAN
                      </p>
                    </div>
                  </div>
                  <div className="news-two__content">
                    <div className="news-two__user-and-meta">
                      <div className="news-two__user">
                        <div className="news-two__user-img">
                          <img src="img/news-two-user-img.jpg" alt="" />
                        </div>
                        <div className="news-two__user-text">
                          <p>
                            by <br />
                            Admin
                          </p>
                        </div>
                      </div>
                      <div className="news-two__meta">
                        <div className="icon">
                          <span className="fas fa-comments"></span>
                        </div>
                        <div className="text">
                          <p>2 Comments</p>
                        </div>
                      </div>
                    </div>
                    <h3 className="news-two__title">
                      <a href="news-details.html">
                        Sargodha Gymkhana Coffee Shop
                      </a>
                    </h3>
                    <p className="news-two__text">
                      There are many variations of passages of available but the
                      majority.
                    </p>
                    <div className="news-two__btn">
                      <Link to="/blogs/1">
                        <a href="news-details.html">
                          Read More
                          <i className="fas fa-long-arrow-alt-right"></i>
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            {blogPage && (
              <>
                <div
                  className="col-xl-6 col-lg-6 wow fadeInUp animated"
                  data-wow-delay="200ms"
                >
                  <Link to="/blogs/1">
                    <div className="news-two__single">
                      <div className="news-two__img-box">
                        <div className="news-two__img">
                          <img src="img/b3.png" alt="" />
                        </div>
                        <div className="news-two__date">
                          <p>
                            30 <br /> JAN
                          </p>
                        </div>
                      </div>
                      <div className="news-two__content">
                        <div className="news-two__user-and-meta">
                          <div className="news-two__user">
                            <div className="news-two__user-img">
                              <img src="img/news-two-user-img.jpg" alt="" />
                            </div>
                            <div className="news-two__user-text">
                              <p>
                                by <br />
                                Admin
                              </p>
                            </div>
                          </div>
                          <div className="news-two__meta">
                            <div className="icon">
                              <span className="fas fa-comments"></span>
                            </div>
                            <div className="text">
                              <p>2 Comments</p>
                            </div>
                          </div>
                        </div>
                        <h3 className="news-two__title">
                          <a href="news-details.html">
                            Sargodha Gymkhana Coffee Shop
                          </a>
                        </h3>
                        <p className="news-two__text">
                          There are many variations of passages of available but
                          the majority.
                        </p>
                        <div className="news-two__btn">
                          <Link to="/blogs/1">
                            <a href="news-details.html">
                              Read More
                              <i className="fas fa-long-arrow-alt-right"></i>
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div
                  className="col-xl-6 col-lg-6 wow fadeInUp animated"
                  data-wow-delay="200ms"
                >
                  <Link to="/blogs/1">
                    <div className="news-two__single">
                      <div className="news-two__img-box">
                        <div className="news-two__img">
                          <img src="images/functions/functions.jpeg" alt="" />
                        </div>
                        <div className="news-two__date">
                          <p>
                            30 <br /> JAN
                          </p>
                        </div>
                      </div>
                      <div className="news-two__content">
                        <div className="news-two__user-and-meta">
                          <div className="news-two__user">
                            <div className="news-two__user-img">
                              <img
                                src="imgages/functions/functions.jpg"
                                alt=""
                              />
                            </div>
                            <div className="news-two__user-text">
                              <p>
                                by <br />
                                Admin
                              </p>
                            </div>
                          </div>
                          <div className="news-two__meta">
                            <div className="icon">
                              <span className="fas fa-comments"></span>
                            </div>
                            <div className="text">
                              <p>2 Comments</p>
                            </div>
                          </div>
                        </div>
                        <h3 className="news-two__title">
                          <a href="news-details.html">
                            Sargodha Gymkhana Coffee Shop
                          </a>
                        </h3>
                        <p className="news-two__text">
                          There are many variations of passages of available but
                          the majority.
                        </p>
                        <div className="news-two__btn">
                          <Link to="/blogs/1">
                            <a href="news-details.html">
                              Read More
                              <i className="fas fa-long-arrow-alt-right"></i>
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div
                  className="col-xl-6 col-lg-6 wow fadeInUp animated"
                  data-wow-delay="200ms"
                >
                  <Link to="/blogs/1">
                    <div className="news-two__single">
                      <div className="news-two__img-box">
                        <div className="news-two__img">
                          <img src="images/frontend/front1.jpeg" alt="" />
                        </div>
                        <div className="news-two__date">
                          <p>
                            30 <br /> JAN
                          </p>
                        </div>
                      </div>
                      <div className="news-two__content">
                        <div className="news-two__user-and-meta">
                          <div className="news-two__user">
                            <div className="news-two__user-img">
                              <img
                                src="imgages/functions/functions.jpg"
                                alt=""
                              />
                            </div>
                            <div className="news-two__user-text">
                              <p>
                                by <br />
                                Admin
                              </p>
                            </div>
                          </div>
                          <div className="news-two__meta">
                            <div className="icon">
                              <span className="fas fa-comments"></span>
                            </div>
                            <div className="text">
                              <p>2 Comments</p>
                            </div>
                          </div>
                        </div>
                        <h3 className="news-two__title">
                          <a href="news-details.html">
                            Sargodha Gymkhana Coffee Shop
                          </a>
                        </h3>
                        <p className="news-two__text">
                          There are many variations of passages of available but
                          the majority.
                        </p>
                        <div className="news-two__btn">
                          <Link to="/blogs/1">
                            <a href="news-details.html">
                              Read More
                              <i className="fas fa-long-arrow-alt-right"></i>
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div
                  className="col-xl-6 col-lg-6 wow fadeInUp animated"
                  data-wow-delay="200ms"
                >
                  <Link to="/blogs/1">
                    <div className="news-two__single">
                      <div className="news-two__img-box">
                        <div className="news-two__img">
                          <img src="images/frontend/front2.jpeg" alt="" />
                        </div>
                        <div className="news-two__date">
                          <p>
                            30 <br /> JAN
                          </p>
                        </div>
                      </div>
                      <div className="news-two__content">
                        <div className="news-two__user-and-meta">
                          <div className="news-two__user">
                            <div className="news-two__user-img">
                              <img
                                src="imgages/functions/functions.jpg"
                                alt=""
                              />
                            </div>
                            <div className="news-two__user-text">
                              <p>
                                by <br />
                                Admin
                              </p>
                            </div>
                          </div>
                          <div className="news-two__meta">
                            <div className="icon">
                              <span className="fas fa-comments"></span>
                            </div>
                            <div className="text">
                              <p>2 Comments</p>
                            </div>
                          </div>
                        </div>
                        <h3 className="news-two__title">
                          <a href="news-details.html">
                            Sargodha Gymkhana Coffee Shop
                          </a>
                        </h3>
                        <p className="news-two__text">
                          There are many variations of passages of available but
                          the majority.
                        </p>
                        <div className="news-two__btn">
                          <Link to="/blogs/1">
                            <a href="news-details.html">
                              Read More
                              <i className="fas fa-long-arrow-alt-right"></i>
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Blog;

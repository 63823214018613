import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="site-footer">
      <div className="site-footer__img">
        <img src="img/site-footer-img.jpg" alt="" />
      </div>
      <div className="container">
        <div className="site-footer__top">
          <div className="footer-widget__logo">
            <Link to="/">
              <img src="img/logo.png" alt="" />
            </Link>
          </div>
          <div className="footer-widget__subscribe-box">
            <div className="footer-widget__subscribe-text">
              <p>Subscribe to Newsletter</p>
            </div>
            <form
              className="footer-widget__email-box mc-form"
              data-url="#"
              novalidate="true"
            >
              <div className="footer-widget__email-input-box">
                <input type="email" placeholder="Email Address" name="EMAIL" />
              </div>
              <button
                type="submit"
                className="footer-widget__subscribe-btn thm-btn"
              >
                Subscribe
              </button>
            </form>
            <div className="mc-form__response"></div>
          </div>
        </div>
        <div className="site-footer__middle">
          <div className="row">
            <div
              className="col-lg-3 col-6 col-sm-6 wow fadeInUp animated"
              data-wow-delay="100ms"

              //  style="visibility: visible; animation-delay: 100ms; animation-name: fadeInUp;"
            >
              <div className="footer-widget__column footer-widget__Contact">
                <div className="footer-widget__title-box">
                  <h3 className="footer-widget__title">Contact</h3>
                </div>
                <p className="footer-widget__Contact-text">
                  404 club road, main University <br /> Road, Sargodha, Punjab
                </p>
                <ul className="footer-widget__Contact-list list-unstyled">
                  <li className="icon-item list-unstyled hover">
                    <i className="fas fa-envelope icon"></i>
                    <span className="icon-list-text">
                      
                      info@gymkhanasargodha.com
                    </span>
                  </li>
                  <li className="icon-item list-unstyled mt-4 hover">
                    <i className="fas fa-mobile-alt icon"></i>
                    <span className="icon-list-text"> (048) 3768504 </span>
                  </li>
                </ul>
                <div className="site-footer__social">
                  {/* <a href="#">
                    <i className="fab fa-twitter"></i>
                  </a> */}
                  <a href="" className="custom-icon">
                    <Link to="https://www.facebook.com/people/Sargodha-Gymkhana-Club/61552216550636/?mibextid=ZbWKwL">
                    <i className="fab fa-facebook-f"></i>
                    </Link>
                  </a>
                  {/* <a href="#">
                    <i className="fab fa-pinterest-p"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-instagram"></i>
                  </a> */}
                </div>
              </div>
            </div>
            <div
              className="col-lg-2 col-6 col-sm-6 ps-3 ps-sm-0 wow fadeInUp animated"
              data-wow-delay="200ms"

              //   style="visibility: visible; animation-delay: 200ms; animation-name: fadeInUp;"
            >
              <div className="footer-widget__column footer-widget__link">
                <div className="footer-widget__title-box">
                  <h3 className="footer-widget__title">Links</h3>
                </div>
                <ul className="footer-widget__link-list list-unstyled">
                  <li>
                    <Link to="/about">
                      <a href="">About us</a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/board-of-governors">
                      <a href="">Board of Governors</a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/gallery">
                      <a href="">Gallery</a>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-lg-2 col-6 col-sm-6 wow fadeInUp animated"
              data-wow-delay="300ms"

              //  style="visibility: visible; animation-delay: 300ms; animation-name: fadeInUp;"
            >
              <div className="footer-widget__column footer-widget__departments">
                <div className="footer-widget__title-box">
                  <h3 className="footer-widget__title">Sections</h3>
                </div>
                <ul className="footer-widget__link-list list-unstyled">
                  {/* <li>
                    <a href="about.html">Cafe  &amp; Green</a>
                  </li> */}
                  <li>
                    <Link to="/gym-area">
                      <a href="">GYM Area</a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/executive-area">
                      <a href="">Executive class</a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/events">
                      <a href="">Events </a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/sports">
                      <a href="">Sports</a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/guest-room">
                      <a href="">Guest Room </a>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-lg-2 col-6 col-sm-6 ps-3 ps-sm-0 wow fadeInUp animated"
              data-wow-delay="400ms"
            >
              <div className="footer-widget__column footer-widget__explore">
                <div className="footer-widget__title-box">
                  <h3 className="footer-widget__title">Explore</h3>
                </div>
                <ul className="footer-widget__link-list list-unstyled">
                  <li>
                    <Link to="/publications">
                      <a href="">Publications</a>
                    </Link>
                  </li>

                  <li>
                    <Link to="/blog">
                      <a href=" ">Latest Blogs</a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/Contact">
                      <a href=" ">Contact</a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">
                      <a href="">Privacy Policy</a>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-lg-3 col-12 col-sm-12 wow fadeInUp animated"
              data-wow-delay="500ms"

              //  style="visibility: visible; animation-delay: 500ms; animation-name: fadeInUp;"
            >
              <div className="footer-widget__column footer-widget__gallery">
                <div className="footer-widget__title-box">
                  <h3 className="footer-widget__title">Gallery</h3>
                </div>
                <Link to="/gallery">
                <div className="row">
                   
                  <div className="col-4 col-sm-4  col-md-4  col-lg-4 col-xl-4 col-xxl-4">
                    <img
                      src="https://i.ibb.co/vsWJHth/event-two-img.png"
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-md-4 col-sm-4 col-4 col-lg-4 col-xl-4 col-xxl-4">
                    <img
                      src="https://i.ibb.co/kmb4yvg/event4.jpg"
                      className="img-fluid"
                    />
                  </div>
                  
                  <div className="col-md-4 col-sm-4 col-4 col-lg-4 col-xl-4 col-xxl-4">
                    <img
                      src="https://i.ibb.co/7jPMZRd/event3.jpg"
                      className="footer-image img-fluid"
                      />
                  </div>
                  <div className="col-md-4 col-sm-4 col-4 col-lg-4 col-xl-4 col-xxl-4">
                    <img
                      src="https://i.ibb.co/hBY3mb3/event2.jpg"
                      className="img-fluid mt-3"
                    />
                  </div>
                  <div className="col-md-4 col-sm-4 col-4 col-lg-4 col-xl-4 col-xxl-4">
                    <img
                      src="https://i.ibb.co/9r3dgMF/event1.jpg"
                      className="img-fluid mt-3"
                    />
                  </div>
                  <div className="col-md-4 col-sm-4 col-4 col-lg-4 col-xl-4 col-xxl-4">
                    <img
                      src="https://i.ibb.co/kmb4yvg/event4.jpg"
                      className="img-fluid mt-3"
                    />
                  </div>
                </div>
                  </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="site-footer__bottom">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="site-footer__bottom-inner">
                <p className="site-footer__bottom-text">
                  © Copyright 2023 by <a href="https://jtechit.com.pk/" target="_blank" >Jtech Developers</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React from "react";
import "./personDetailScreen.css";
import NavbarTwo from "../../components/navbar/NavbarTwo";
import { useState } from "react";
import { dataDetails } from "./dummy";
import { useParams } from "react-router-dom";

const PersonDetailScreen = () => {
  const [data, setdata] = useState(dataDetails);

  return (
    <>
      <NavbarTwo />
      <div className="profil-area section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 my-4 shadow-sm">
              <div className="profile-name-img-area text-center box-shadow2 border-top2 section-background">
                <div className="prfile-img ">
                  <img
                    src="https://i.ibb.co/kMcp1tr/Untitled-design-1.png"
                    alt="profile"
                    className="prfile-img img-responsive"
                  />
                </div>
                <div className="profile-name text-center">
                  <h1> Ahmed Yar Chadhar</h1>
                  <h4 className="cd-headline letters scale">
                    Secretary, Sargodha Gymkhana Club{" "}
                  </h4>
                  {/* <div className="social_profile">
                  <ul className="social-sec">
                    <li>
                      <a
                        href="https://www.facebook.com/naeemlazy?mibextid=ZbWKwL"
                        target="_blank"
                      >
                        <span>
                          <i className="fab fa-facebook-f fa-2x"></i>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/SheikhNaeemLazy?t=qdaTqh4oPJd-6CUDCKCi3A&amp;s=09"
                        target="_blank"
                      >
                        <span>
                          <i className="fab fa-twitter fa-2x"></i>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.tiktok.com/@naeemsheikh257?_t=8YZi7iYyZb0&amp;_r=1"
                        target="_blank"
                        >
                        <span>
                          <i className="fab fa-tiktok fa-2x"></i>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://instagram.com/lazysheikh?igshid=NDk5N2NlZjQ="
                        target="_blank"
                      >
                        <span>
                          <i className="fab fa-instagram fa-2x"></i>
                        </span>
                      </a>
                    </li>

                    <li>
                      <a
                        href="https://youtube.com/@thesheikhsquare3863"
                        target="_blank"
                        >
                        <span>
                          <i className="fab fa-youtube fa-2x"></i>
                        </span>
                      </a>
                    </li>
                  </ul>
                </div> */}
                </div>
              </div>
            </div>
            <div className="col-md-6 my-4 shadow-sm">
              <div className="profile box-shadow2 border-top2 section-background">
                <div className="section-title">
                  <h3>Profile</h3>
                </div>
                <div className="profile-info table-responsive">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <i className="fa fa-user"></i>
                        </td>
                        <td>
                          <span>Name</span>
                        </td>
                        <td>
                          <b>:</b>
                        </td>
                        <td>Ahmad Yar Chadhar</td>
                      </tr>
                      <tr>
                        <td>
                          <i className="fa fa-calendar"></i>
                        </td>
                        <td>
                          <span>Date of Birth</span>
                        </td>
                        <td>
                          <b>:</b>
                        </td>
                        <td>10/02/1993</td>
                      </tr>
                      <tr>
                        <td>
                          <i className="fa fa-map-marker-alt"></i>
                        </td>
                        <td>
                          <span>Address</span>
                        </td>
                        <td>
                          <b>:</b>
                        </td>
                        <td>Shop # 13-14 Gulbergcity</td>
                      </tr>
                      <tr>
                        <td>
                          <i className="fab fa-whatsapp-square"></i>
                        </td>
                        <td>
                          <span>Whatsapp</span>
                        </td>
                        <td>
                          <b>:</b>
                        </td>
                        <td>000000000</td>
                      </tr>
                      <tr>
                        <td>
                          <i className="fa fa-envelope"></i>
                        </td>
                        <td>
                          <span>Email</span>
                        </td>
                        <td>
                          {" "}
                          <b>:</b>
                        </td>
                        <td>example@gmail.com</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="objective-area box-shadow2 border-top2 section-background color">
                <div className="section-title">
                  <h3>Message</h3>
                </div>
                <div className="objective-content">
                  <p>
                    As Secretary, Ahmed Yar Chadhar is responsible for
                    overseeing the day-to-day affairs of the Sargodha Gymkhana
                    Club. His dedication and leadership are instrumental in
                    ensuring that the club provides top-notch facilities for
                    various sporting activities, social gatherings, and
                    recreational events. Under his guidance, the club has
                    flourished and become a hub for both sports enthusiasts and
                    those seeking a friendly and welcoming atmosphere in
                    Sargodha.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonDetailScreen;

import React from "react";
import NavbarTwo from "../../components/navbar/NavbarTwo";
import Slider from "./slider/Slider";
import { useDocumentTitle } from "../../useDocumentTitle";
const GuestRoomForm = () => {
  useDocumentTitle("Guest Room Form")
  return (
    <>
      <NavbarTwo />
      <Slider />
      <div>
        <div className="main_form">
          <div className="container">
            
            <h2 className="text-center my-4">Timing</h2>
            <h3 className="text-center my-4">12:00 PM to 6:00 PM</h3>

            <div className="row d-flex ">
              <div
                className="col-12 col-lg-7 px-sm-3 px-2 pt-lg-0 pt-3 col-lg-pull-4"
                id="left-sec"
              >
                <div className="p-1 p-sm-4 border_main">
                  <div className=" pl-0 pt-3 px-4 pb-0 mb-0 position-relative">
                    <div className="left_space_icon">
                      <i className="fa fa-user font-14"></i>
                    </div>
                    <h4 className="pb-2 xs-font-16 font-14 pl-4"> Guest Detail</h4>
                    <div className="px-2">
                      <div className="col-sm-12 px-1">
                        <div className="form-group mb-3">
                          <label className="font-10 text-muted font-weight-bold mb-1">
                            First Name
                          </label>
                          <input
                            type="text"
                            placeholder=""
                            name="text"
                            className="form-control "
                            value=""
                            id="email"
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 px-1">
                        <div className="form-group mb-3">
                          <label className="font-10 text-muted font-weight-bold mb-1">
                            Last Name
                          </label>
                          <input
                            type="text"
                            placeholder=""
                            name="email"
                            className="form-control "
                            value=""
                            id="email"
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 px-1">
                        <div className="form-group mb-3">
                          <label className="font-10 text-muted font-weight-bold mb-1">
                            Email Address
                          </label>
                          <input
                            type="text"
                            placeholder=""
                            name="text"
                            className="form-control "
                            value=""
                            id="email"
                          />
                        </div>
                      </div>
                      <div className="form-group col-sm-12 px-1">
                        <div className="form-group mb-0">
                          <label className="font-10 text-muted font-weight-bold mb-1">
                            Phone Number
                          </label>
                          <input
                            type="text"
                            name="phone"
                            id="PhoneNumber"
                            placeholder=""
                            className="form-control"
                          />
                        </div>
                      </div>
                      
                      <button className="btn btn-lg my-4 btn-block booking-btn font-weight-bold font-custom">
                        Confirm Reservation
                      </button>
                    </div>
                  </div>
   
                </div>
              </div>
              <div className="col-12 col-lg-5 ">
                <img src="/img/r6.jpg" width='100%' height='100%' alt="" />
              </div>

            </div>
            <div className="mt-4 ">

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GuestRoomForm;

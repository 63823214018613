import React from "react";
import "./privacyPolicy.css";
import { FiberManualRecordRounded } from "@mui/icons-material";
import { data } from "./dummy";
import { useDocumentTitle } from "../../useDocumentTitle";
import NavbarTwo from "../../components/navbar/NavbarTwo";
import Slider from "../../pagesComponents/contactUs/slider/Slider";
const PrivacyPolicy = () => {
  useDocumentTitle("Privacy Policy");

  return (
    <>
      <NavbarTwo />
      <div className="terms_condition_slider_containerWrapper">
      <Slider about="Privacy Policy" />
        <div className="privacy_policy_container">
          {/* <h1 className="privacy_policy_heading"></h1> */}

          <div className="privacy_policy_wrapper">
            {data.map((question) => (
              <div className="policy_heading_desc" key={question.id}>
                <h1 className="policy_heading_title">{question.question}</h1>
                <p className="policy_desc_details">{question.answer}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;

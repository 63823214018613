import React from "react";
import "./contact.css";
import Form from "../../pagesComponents/contactUs/form/Form";
import Details from "../../pagesComponents/contactUs/details/Details";
import Slider from "../../pagesComponents/contactUs/slider/Slider";
import NavbarTwo from "../../components/navbar/NavbarTwo";
import { useDocumentTitle } from "../../useDocumentTitle";
const Contact = () => {
  useDocumentTitle("Contact us")

  return (
    <>
    <NavbarTwo/>
    <Slider about="Contact Us" />
    <div>
      
      <div className="conatct_Page_main_allComponentWrapper">

      {/* <h1 className="contact_heading">CONTACT US</h1> */}
     
      <div className="contact_form_detail">
        <Form />
        <Details />
      </div>
      </div>
    </div>
    </>
  );
};

export default Contact;

import React from "react";
import "../personDetailScreen.css";
import NavbarTwo from "../../../components/navbar/NavbarTwo";

const AhmedYarChadhar = () => {

  return (
    <>
      <NavbarTwo />
      <div className="profil-area section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 my-4 shadow-sm">
              <div className="profile-name-img-area text-center box-shadow2 border-top2 section-background">
                <div className="prfile-img ">
                  <img
                    // src="https://i.ibb.co/gmVTRY8/Untitled-design-3.png"
                    src="https://i.ibb.co/gmVTRY8/Untitled-design-3.png"
                   
                     
                    alt="profile"
                    className="prfile-img img-responsive"
                  />
                </div>
                <div className="profile-name text-center">
                  <h1>Mohammad Mohsin Sallahudin</h1>
                  <h5 className="cd-headline letters scale">
                    Additional Deputy Commissioner (Revenue) / Secretary
                    Finance, Sargodha Gymkhana Club{" "}
                  </h5>
                 
                </div>
              </div>
            </div>
            <div className="col-md-6 my-4 shadow-sm">
              <div className="profile box-shadow2 border-top2 section-background">
                <div className="section-title">
                  <h3>Profile</h3>
                </div>
                <div className="profile-info table-responsive">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <i className="fa fa-user"></i>
                        </td>
                        <td>
                          <span>Name</span>
                        </td>
                        <td>
                          <b>:</b>
                        </td>
                        <td>Mohammad Mohsin Sallahudin</td>
                      </tr>
                      <tr>
                        <td>
                          <i className="fa fa-calendar"></i>
                        </td>
                        <td>
                          <span>Date of Birth</span>
                        </td>
                        <td>
                          <b>:</b>
                        </td>
                        <td>10/02/1993</td>
                      </tr>
                      <tr>
                        <td>
                          <i className="fa fa-map-marker-alt"></i>
                        </td>
                        <td>
                          <span>Address</span>
                        </td>
                        <td>
                          <b>:</b>
                        </td>
                        <td>Shop # 13-14 Gulbergcity</td>
                      </tr>
                      <tr>
                        <td>
                          <i className="fab fa-whatsapp-square"></i>
                        </td>
                        <td>
                          <span>Whatsapp</span>
                        </td>
                        <td>
                          <b>:</b>
                        </td>
                        <td>000000000</td>
                      </tr>
                      <tr>
                        <td>
                          <i className="fa fa-envelope"></i>
                        </td>
                        <td>
                          <span>Email</span>
                        </td>
                        <td>
                          {" "}
                          <b>:</b>
                        </td>
                        <td>example@gmail.com</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="objective-area box-shadow2 border-top2 section-background color">
                <div className="section-title">
                  <h3>Message</h3>
                </div>
                <div className="objective-content">
                  <p>
                    In his role as Secretary Finance at the Sargodha Gymkhana
                    Club, Mohammad Mohsin Sallahudin showcases his financial
                    acumen and organizational skills. The Sargodha Gymkhana Club
                    is a prominent social and recreational hub in the region,
                    and his role involves managing and maintaining the club's
                    financial operations. He works diligently to ensure the
                    club's fiscal health, contributing to the success and
                    sustainability of this esteemed institution.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AhmedYarChadhar;

import React from "react";
import Card from "../../pagesComponents/facility/Card";
import NavbarTwo from "../../components/navbar/NavbarTwo";
import Slider from "../../pagesComponents/contactUs/slider/Slider";
import { useDocumentTitle } from "../../useDocumentTitle";

const Facility = () => {
  useDocumentTitle("Board Of Governors")

  return (
    <div>
      <NavbarTwo />
      <Slider about="Board of Governors "  />
      <Card />
    </div>
  );
};

export default Facility;

import React from 'react'
import { Link } from 'react-router-dom'

const Counter = () => {
  return (
    <section className="counter-two">
    <div className="counter-two__bg">
    </div>
    <div className="container">
       <div className="section-title text-center join_contact">
          <div className="section-title__icon">
             <span className="fa fa-star"></span>
          </div>
          <span className="section-title__tagline">Gymkhana Members</span>
          <h2 className="section-title__title mb-5">Please Join Us
             <br/> We’re Delivering the best Members Experience
          </h2>
          <Link to="/contact"  className="thm-btn main-menu-two__btn">
          <span>
          Contact Us     </span>
          </Link>
       </div>
    </div>
 </section>
  )
}

export default Counter

import React from "react";
import PublicationsCards from "../../pagesComponents/publications/PublicationCards";
import NavbarTwo from "../../components/navbar/NavbarTwo";
import { useDocumentTitle } from "../../useDocumentTitle";

const Publications = () => {
  useDocumentTitle("Publications")

  return (
    <div>
      <NavbarTwo />
      <div className="section-title text-center">
        <div className="section-title__icon">
          <span className="fa fa-star"></span>
        </div>
        <span className="section-title__tagline">Latest Publications</span>
        <h2 className="section-title__title">
          Latest news &amp; Publications
          {/* <br /> from the Research Center */}
        </h2>
      </div>
      <PublicationsCards />
    </div>
  );
};

export default Publications;

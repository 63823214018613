import React from "react";
import { Link } from "react-router-dom";
import "./features.css";

const Features = () => {
  const image =
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzpVJO092Z2X_zLv3SakcftktN-6pjkXNM-M5e4qXQbojXdZbHLgBUwDIUZiiGsG01nkg&usqp=CAU";
  return (
    <div className="container-fluid feature feature_cat">
      <div className="row gv-d d-flex justify-content-center">
        <div className=" col-lg-2 col-md-4 p-3 jr-s">
          <div className=" shadow bg-white p-2">
            <div className="p-3 position-relative">
              <div className="d-flex  justify-content-between hg-s">
                {/* <h4 className="mt-4 ">01</h4> */}
                <i className="fas fa-utensils fs-5 p-4 mt-1 bg-light"></i>
              </div>
              <div className="mt-3 bc-r ">
                <h6>BOOK</h6>
                <h5>A Table at Restaurant</h5>
                {/*  to={(`/booking-form`, data)}> */}
                {/* <Link to={"/booking-form"}> */}

                <Link to="/booking-form" className="thm-btn main-menu-two__btn">
                  <span>BOOK NOW </span>
                </Link>
              </div>
              <img
                src="https://themekar.com/templatebucket/techlo/techlo/assets/img/shape/choose-shape.png"
                alt=""
                className="position_absolute"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-2 col-md-4 p-3 jr-s">
          <div className=" shadow bg-white p-2">
            <div className="p-3 position-relative">
              <div className="d-flex  justify-content-between hg-s">
                {/* <h4 className="mt-4 ">02</h4> */}
                <i className="fas fa-bed fs-5 p-4 mt-1 bg-light"></i>
              </div>
              <div className="mt-3 bc-r">
                <h6>BOOK</h6>
                <h5>A Guest Room</h5>
                <Link
                  to="/guest-room-form"
                  className="thm-btn main-menu-two__btn"
                >
                  <span>BOOK NOW </span>
                </Link>
              </div>
              <img
                src="https://themekar.com/templatebucket/techlo/techlo/assets/img/shape/choose-shape.png"
                alt=""
                className="position_absolute"
              />
            </div>
          </div>
        </div>
        <div className=" col-lg-2 col-md-4 p-3 jr-s">
          <div className=" shadow bg-white p-2">
            <div className="p-3 position-relative">
              <div className="d-flex justify-content-between hg-s">
                {/* <h4 className="mt-4 ">03</h4> */}
                <i className="fas fa-film fs-5 p-4 mt-1 bg-light"></i>
              </div>
              <div className="mt-3 bc-r">
                <h6>Buy </h6>
                <h5>Cinema Tickets</h5>
                <Link
                  to="/cinema-shop-form"
                  className="thm-btn main-menu-two__btn"
                >
                  <span>BOOK NOW </span>
                </Link>
              </div>
              <img
                src="https://themekar.com/templatebucket/techlo/techlo/assets/img/shape/choose-shape.png"
                alt=""
                className="position_absolute"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-2 col-md-4 p-3 jr-s">
          <div className=" shadow bg-white p-2">
            <div className="p-3 position-relative">
              <div className="d-flex  justify-content-between hg-s">
                {/* <h4 className="mt-4 ">04</h4> */}
                <i className="fas fa-hat-cowboy fs-5 p-4 mt-1 bg-light"></i>
              </div>
              <div className="mt-3 bc-r">
                <h6>BOOK</h6>
                <h5>Riding Club</h5>
                <Link to="/riding-form" className="thm-btn main-menu-two__btn">
                  <span>BOOK NOW </span>
                </Link>
              </div>
              <img
                src="https://themekar.com/templatebucket/techlo/techlo/assets/img/shape/choose-shape.png"
                alt=""
                className="position_absolute"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-2 col-md-4 p-3 jr-s">
          <div className=" shadow bg-white p-2">
            <div className="p-3 position-relative">
              <div className="d-flex  justify-content-between hg-s">
                {/* <h4 className="mt-4 ">05</h4> */}
                <i className="far fa-calendar-alt fs-5 p-4 mt-1 bg-light"></i>
              </div>
              <div className="mt-3 bc-r">
                <h6>BOOK</h6>
                <h5>Coffee Shop</h5>
                <Link
                  to="/coffee-shop-form"
                  className="thm-btn main-menu-two__btn"
                >
                  <span>BOOK NOW </span>
                </Link>
              </div>
              <img
                src="https://themekar.com/templatebucket/techlo/techlo/assets/img/shape/choose-shape.png"
                alt=""
                className="position_absolute"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;

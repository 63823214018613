import React from "react";
import NavbarTwo from "../../../components/navbar/NavbarTwo";
import ButtonComponent from "../../../components/buttonComponent/ButtonComponent";
const Events = () => {
  return (
    <>
      <NavbarTwo />
      <div className="cinema_container">
        <div className="cinema_wrapper">
          <img src="/images/functions/functions.jpeg" alt="" className="picture_images" />
          </div>
          <div className="once_description">
            <h1>
              A Guide to Gymkhana Events: Riding Through Agility and Speed
            </h1>
            <p>
              Gymkhana events are typically equestrian competitions that test
              the skills and agility of both the horse and rider. These events
              can vary in format and rules, but some of the common text events
              you might find in a gymkhana include:
              <br />
              Barrel Racing: In barrel racing, riders navigate a cloverleaf
              pattern around three barrels set up in a triangle formation. The
              goal is to complete the pattern as quickly as possible without
              knocking over any barrels. Pole Bending: In pole bending, riders
              weave their horse through a series of evenly spaced poles without
              knocking any of them down.
              <br />
            </p>
            <ButtonComponent title="Reserve Now"/>
            <img src="/img/feature-three-img-2.jpg" alt="" className="image_sub" />

            <p>
              Speed Ball Race: In this event, riders pick up a ball from one
              barrel, carry it to another barrel, and then drop it in the
              barrel. Speed and agility are essential. Flag and Stake Race:
              Riders must pick up a flag from a barrel and then weave their
              horse through a series of stakes in the ground before depositing
              the flag in another barrel
              <br />
              To cater to the needs of gym-goers, gymkhanas typically have
              changing rooms and shower facilities in or near the gym area. This
              allows people to freshen up before or after their workouts.
              <br />
              <br />
              racing but with the added challenge of picking up and depositing
              flags in barrels while navigating the cloverleaf pattern. These
              gymkhana events are not only fun for participants and spectators
              but also help riders and horses develop important skills such as
              agility, speed, control, and precision. The specific rules and
              variations of these events can vary depending on the organization
              and location of the gymkhana.
            </p>
            <img src="/img/event2.jpg" alt="" className="image_sub" />
          </div>
     
      </div>
    </>
  );
};

export default Events;

import React from "react";
import NavbarTwo from "../../../components/navbar/NavbarTwo";
import "../section.css"
import ButtonComponent from "../../../components/buttonComponent/ButtonComponent";
const GymArea = () => {
  return (
    <>
      <NavbarTwo />
      <div className="cinema_container">
        <div className="cinema_wrapper">
          <img src="/images/gym/gym2.jpeg" alt="" className="picture_images" />
        </div>

          <div className="once_description">
            <h1>
              Exploring the World of Sports: The Enthralling Journey of Gymkhana
            </h1>
            <p>
              A gymkhana is a term often used to describe a type of event or
              competition that takes place in a gym or sports facility. It's a
              place where people engage in various physical activities and
              exercises to improve their fitness, strength, and overall
              well-being. In a gymkhana, you'll typically find a dedicated gym
              area equipped with a variety of exercise equipment and amenities.
              Here's some information about what you might expect to find in the
              gym area of a gymkhana:
              <br />
              Gym areas in gymkhanas are equipped with a wide range of exercise
              machines and equipment. This can include treadmills, stationary
              bikes, elliptical trainers, weightlifting machines, free weights,
              and more. The variety of equipment caters to different fitness
              goals and preferences.
              <br />
            </p>
            <ButtonComponent title="Join Now"/>

            <img src="/images/gym/gym6.jpeg" alt="" className="image_sub" />

            <p>
              Gym areas also often have space for functional training exercises.
              This might include areas for bodyweight exercises, stretching,
              yoga, or functional training equipment such as stability balls and
              resistance bands.
              <br />
              To cater to the needs of gym-goers, gymkhanas typically have
              changing rooms and shower facilities in or near the gym area. This
              allows people to freshen up before or after their workouts.
              <br />
              <br />
              Some gymkhanas offer personal training services. Trainers work
              with individuals to create customized fitness programs and provide
              guidance on achieving specific fitness goals.
            </p>
            <img src="/images/gym/gym5.jpeg" alt="" className="image_sub" />
          </div>
      </div>
    </>
  );
};

export default GymArea;

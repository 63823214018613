 
import React from "react";
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <section className="feature-three py-5">
      <div className="container mt-4">
        <div className="row">
          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-6">
                <div className="feature-box">
                  <h3 className="feature-title">
                    History of
                    <br />
                    Sargodha Gymkhana 
                  </h3>
                  <Link to="/publication/1">
                    <p className="feature-btn">
                      Read More 
                      <i className="fas fa-long-arrow-alt-right f-btn"></i>
                    </p>
                  </Link>
                </div>
              </div>
              <div className="col-lg-6">
                <img src="img/feature-three-img-1.jpg" className="f-img" />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-6">
                <div className="feature-box2">
                  <h3 className="feature-title">
                    News <br />
                    &amp; Events
                  </h3>
                  <Link to="/publication/1">
                    <p className="feature-btn ">
                      Read More 
                      <i className="fas fa-long-arrow-alt-right f-btn"></i>
                    </p>
                  </Link>
                </div>
              </div>
              <div className="col-lg-6">
                <img src="img/feature-three-img-2.jpg" className="f-img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;

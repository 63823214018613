import React from "react";
import NavbarTwo from "../../../components/navbar/NavbarTwo";
import ButtonComponent from "../../../components/buttonComponent/ButtonComponent";
const Sports = () => {
  return (
    <>
      <NavbarTwo />
      <div className="cinema_container">
        <div className="cinema_wrapper">
          <img
            src="/images/sports/sport10.jpeg"
            alt=""
            className="picture_images"
          />
           </div>
          <div className="once_description">
            <h1>
              Exploring the World of Sports: The Enthralling Journey of Gymkhana
            </h1>
            <p>
              "Gymkhana" is a term commonly associated with equestrian events,
              particularly in British English. It refers to a type of
              horse-riding competition that involves various activities and
              challenges, often in a controlled arena. Some of the typical
              sports or activities that may be part of a gymkhana event include:
              <br />
              Ride-A-Buck: Riders place a dollar bill between their thigh and
              the saddle and attempt to maintain control while riding.
              <br />
            </p>
            <ButtonComponent title="Join Now"/>

            <img
              src="/images/sports/sport3.jpeg"
              alt=""
              className="image_sub"
            />

            <p>
              The keyhole race is a test of precision, as riders must guide
              their horses into a tight keyhole-shaped pattern and then back out
              again as quickly as possible. Stake racing involves circling a
              series of stakes in a specific order, showcasing the horse's
              agility and the rider's ability to guide them accurately.
              <br />
              Personalized service: VIPs may receive more attention and
              personalized assistance from staff. Reserved seating: VIP areas
              often have reserved seating, which can be advantageous for events
              or busy periods. Catering services: VIP areas may offer exclusive
              food and beverage options. Access control: These areas might have
              restricted access to ensure only authorized individuals can enter.
              <br />
              <br />
              These events are typically aimed at showcasing the skills of both
              the horse and the rider, emphasizing speed, control, and accuracy.
              Gymkhana events are often fun and competitive, making them a
              popular attraction at equestrian gatherings, fairs, and rodeos.
              It's important to note that the specific events and rules of
              gymkhanas may vary depending on the region and organizing body.
            </p>
            <img
              src="/images/sports/sport8.jpeg"
              alt=""
              className="image_sub"
            />
          </div>
       
      </div>
    </>
  );
};

export default Sports;

import React from "react";
import { Link } from "react-router-dom";

const Events = ({ eventsPage }) => {
  return (
    <section className="department-two" id="departments">
      <div className="department-two__bg-one"></div>
      <div className="department-two__bg-two"></div>
      <div className="container">
        <div className="section-title text-center">
          <div className="section-title__icon">
            <span className="fa fa-star"></span>
          </div>
          <span className="section-title__tagline">Our Events</span>
          <h2 className="section-title__title">
            Explore Sargodha Gymkhana
            <br /> Events
          </h2>
        </div>
        <div className="row">
          <div className="col-xl-3 col-md-6 col-lg-3">
            <div className="department-two__single">
              <div className="department-two__img-box">
                <div className="department-two__img">
                  <img src="img/event1.jpg" alt="" />
                </div>
                <div className="department-two__content">
                  <h3 className="department-two__title">
                    <Link to="/events">
                     
                      Gymkhana
                      <br /> Cricket Club
                    </Link>
                  </h3>
                  <div className="department-two__icon">
                    <span className="icon-parthenon">
                      <i className="fas fa-university"></i>
                    </span>
                  </div>
                </div>
                <div className="department-two__content-two">
                  <h3 className="department-two__title-two">
                    <Link to="/events">
                      Gymkhana
                      <br /> Cricket Club
                    </Link>
                  </h3>
                  <p className="department-two__text">
                    There are many variations of pass the majority.
                  </p>
                  <div className="department-two__btn-box">
                    <Link
                    to="/events"
                      className="department-two__btn"
                    >
                      Read More<i className="fas fa-long-arrow-alt-right"></i>
                    </Link>
                  </div>
                  <div className="department-two__icon-two">
                    <span className="icon-parthenon">
                      <i className="fas fa-university"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 col-lg-3">
            <div className="department-two__single">
              <div className="department-two__img-box">
                <div className="department-two__img">
                  <img src="img/event2.jpg" alt="" />
                </div>
                <div className="department-two__content">
                  <h3 className="department-two__title">
                    <Link to="/events">
                      Gymkhana <br />
                      badminton Club
                    </Link>
                  </h3>
                  <div className="department-two__icon">
                    <span className="icon-suitcase">
                      <i className="fas fa-university"></i>
                    </span>
                  </div>
                </div>
                <div className="department-two__content-two">
                  <h3 className="department-two__title-two">
                    <Link to="/events">
                      Gymkhana
                      <br /> badminton Club
                    </Link>
                  </h3>
                  <p className="department-two__text">
                    There are many variations of pass the majority.
                  </p>
                  <div className="department-two__btn-box">
                    <Link
                    to="/events"
                      className="department-two__btn"
                    >
                      Read More<i className="fas fa-long-arrow-alt-right"></i>
                    </Link>
                  </div>
                  <div className="department-two__icon-two">
                    <span className="icon-suitcase">
                      <i className="fas fa-university"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 col-lg-3">
            <div className="department-two__single">
              <div className="department-two__img-box">
                <div className="department-two__img">
                  <img src="img/event3.jpg" alt="" />
                </div>
                <div className="department-two__content">
                  <h3 className="department-two__title">
                    <Link to="/events">
                      Gymkhana <br />
                      table tennis
                    </Link>
                  </h3>
                  <div className="department-two__icon">
                    <span className="icon-industry">
                      <i className="fas fa-university"></i>
                    </span>
                  </div>
                </div>
                <div className="department-two__content-two">
                  <h3 className="department-two__title-two">
                    <Link to="/events">
                      Gymkhana
                      <br /> table tennis
                    </Link>
                  </h3>
                  <p className="department-two__text">
                    There are many variations of pass the majority.
                  </p>
                  <div className="department-two__btn-box">
                    <Link
                    to="/events"
                      className="department-two__btn"
                    >
                      Read More<i className="fas fa-long-arrow-alt-right"></i>
                    </Link>
                  </div>
                  <div className="department-two__icon-two">
                    <span className="icon-industry">
                      <i className="fas fa-university"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 col-lg-3">
            <div className="department-two__single">
              <div className="department-two__img-box">
                <div className="department-two__img">
                  <img src="img/event4.jpg" alt="" />
                </div>
                <div className="department-two__content">
                  <h3 className="department-two__title">
                    <Link to="events">
                      Gymkhana
                      <br />
                      horse riding
                    </Link>
                  </h3>
                  <div className="department-two__icon">
                    <span className="icon-bus">
                      <i className="fas fa-university"></i>
                    </span>
                  </div>
                </div>
                <div className="department-two__content-two">
                  <h3 className="department-two__title-two">
                    <Link to="/events">
                      Gymkhana
                      <br /> horse riding
                    </Link>
                  </h3>
                  <p className="department-two__text">
                    There are many variations of pass the majority.
                  </p>
                  <div className="department-two__btn-box">
                    <Link
                    to="/events"
                      className="department-two__btn"
                    >
                      Read More<i className="fas fa-long-arrow-alt-right"></i>
                    </Link>
                  </div>
                  <div className="department-two__icon-two">
                    <span className="icon-bus">
                      <i className="fas fa-university"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ++++++++++++++++++++++++++ more evensts *************************************** */}

          {eventsPage && (
            <>
              <div className="col-xl-3 col-md-6 col-lg-3">
                <div className="department-two__single">
                  <div className="department-two__img-box">
                    <div className="department-two__img">
                      <img src="img/sargodha_gymkhana_club_4.jpg" alt="" />
                    </div>
                    <div className="department-two__content">
                      <h3 className="department-two__title">
                        <Link to="/events">
                          Gymkhana
                          <br />
                          get together
                        </Link>
                      </h3>
                      <div className="department-two__icon">
                        <span className="icon-bus">
                          <i className="fas fa-university"></i>
                        </span>
                      </div>
                    </div>
                    <div className="department-two__content-two">
                      <h3 className="department-two__title-two">
                        <Link to="/events">
                          Gymkhana
                          <br /> horse riding
                        </Link>
                      </h3>
                      <p className="department-two__text">
                        There are many variations of pass the majority.
                      </p>
                      <div className="department-two__btn-box">
                        <a
                        to="events"
                          className="department-two__btn"
                        >
                          Read More
                          <i className="fas fa-long-arrow-alt-right"></i>
                        </a>
                      </div>
                      <div className="department-two__icon-two">
                        <span className="icon-bus">
                          <i className="fas fa-university"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-lg-3">
                <div className="department-two__single">
                  <div className="department-two__img-box">
                    <div className="department-two__img">
                      <img src="img/sargodha_gymkhana_club_3.jpg" alt="" />
                    </div>
                    <div className="department-two__content">
                      <h3 className="department-two__title">
                        <Link to="/events">
                          Gymkhana
                          <br />
                          horse riding
                        </Link>
                      </h3>
                      <div className="department-two__icon">
                        <span className="icon-bus">
                          <i className="fas fa-university"></i>
                        </span>
                      </div>
                    </div>
                    <div className="department-two__content-two">
                      <h3 className="department-two__title-two">
                        <Link to="/events">
                          Gymkhana
                          <br /> horse riding
                        </Link>
                      </h3>
                      <p className="department-two__text">
                        There are many variations of pass the majority.
                      </p>
                      <div className="department-two__btn-box">
                        <Link
                        to="events"
                          className="department-two__btn"
                        >
                          Read More
                          <i className="fas fa-long-arrow-alt-right"></i>
                        </Link>
                      </div>
                      <div className="department-two__icon-two">
                        <span className="icon-bus">
                          <i className="fas fa-university"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-lg-3">
                <div className="department-two__single">
                  <div className="department-two__img-box">
                    <div className="department-two__img">
                      <img src="img/event1.png" alt="" />
                    </div>
                    <div className="department-two__content">
                      <h3 className="department-two__title">
                        <Link to="/events">
                          Gymkhana
                          <br />
                          horse riding
                        </Link>
                      </h3>
                      <div className="department-two__icon">
                        <span className="icon-bus">
                          <i className="fas fa-university"></i>
                        </span>
                      </div>
                    </div>
                    <div className="department-two__content-two">
                      <h3 className="department-two__title-two">
                        <Link to="/events">
                          Gymkhana
                          <br /> horse riding
                        </Link>
                      </h3>
                      <p className="department-two__text">
                        There are many variations of pass the majority.
                      </p>
                      <div className="department-two__btn-box">
                        <Link
                          to="/events"
                          className="department-two__btn"
                        >
                          Read More
                          <i className="fas fa-long-arrow-alt-right"></i>
                        </Link>
                      </div>
                      <div className="department-two__icon-two">
                        <span className="icon-bus">
                          <i className="fas fa-university"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-lg-3">
                <div className="department-two__single">
                  <div className="department-two__img-box">
                    <div className="department-two__img">
                      <img src="img/event2.png" alt="" />
                    </div>
                    <div className="department-two__content">
                      <h3 className="department-two__title">
                        <Link to="/events">
                          Gymkhana
                          <br />
                          horse riding
                        </Link>
                      </h3>
                      <div className="department-two__icon">
                        <span className="icon-bus">
                          <i className="fas fa-university"></i>
                        </span>
                      </div>
                    </div>
                    <div className="department-two__content-two">
                      <h3 className="department-two__title-two">
                        <Link to="/events">
                          Gymkhana
                          <br /> horse riding
                        </Link>
                      </h3>
                      <p className="department-two__text">
                        There are many variations of pass the majority.
                      </p>
                      <div className="department-two__btn-box">
                        <Link
                          to="/events"
                          className="department-two__btn"
                        >
                          Read More
                          <i className="fas fa-long-arrow-alt-right"></i>
                        </Link>
                      </div>
                      <div className="department-two__icon-two">
                        <span className="icon-bus">
                          <i className="fas fa-university"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default Events;

export const data=[
    {
        id:1,
        question:"Personal Information",
        answer:"We may collect personal information such as your name, contact information, address, and other details necessary for providing our services. This information is collected when you register as a member or use our facilities."
    },
    {
        id:2,
        question:"Payment Information:",
        answer:"When you make payments for our services, we may collect payment information such as credit card details, billing addresses, and other transaction-related data. We use secure third-party payment rocessors to handle this information securely."
    },
    {
        id:3,
        question:"Data Usage",
        answer:"We may collect information about how you interact with our services, including log files, device information, and browsing activity. This data helps us improve our services and enhance your user experience."
    },
    {
        id:4,
        question:"Legal Compliance:",
        answer:"We may use your information to comply with applicable laws, regulations, or legal processes, and to protect our rights and interests."
    },
    {
        id:422,
        question:"Legal Requirements:",
        answer:"We may disclose your information in response to legal requests or as required by law"
    },
    {
        id:4222,
        question:"Data Security",
        answer:"We implement industry-standard security measures to protect your information from unauthorized access, disclosure, alteration, and destruction. However, no data transmission over the internet or storage system is guaranteed to be 100% secure. You use our services at your own risk"
    },
    {
        id:4222,
        question:"Marketing and Communications",
        answer:"With your consent, we may use your contact information to send you promotional materials, newsletters, or updates about our services and events. You can opt out of these communications at any time."
    },
    {
        id:251,
        question:"Changes to this Privacy Policy",
        answer:"We reserve the right to update or modify this Privacy Policy at any time. The latest version will be posted on our website, and the date of the last update will be indicated. Your continued use of our services after any changes indicates your acceptance of the updated Privacy Policy."
    },
    {
        id:554,
        question:"Contact Us",
        answer:"If you have any questions, concerns, or requests regarding this Privacy Policy, please contact us."
    },

   
]


export const gym = [
  {
    id: 1,
    img: "images/gym/gyn1.jpeg",
    text:"TreadMil"
  },
  {
    id: 2,
    img: "images/gym/gym5.jpeg",
    text:"INCLINE BENCH PRESS"
  },
  {
    id: 3,
    img: "images/gym/gym4.jpeg",
    text:" DECLINE BENCH"
  },
  {
    id: 4,
    img: "images/gym/gym2.jpeg",
    text:"ADJUSTABLE BENCH"
  },
  {
    id: 4,
    img: "images/gym/gym6.jpeg",
    text:"OLYMPIC WEIGHT BENCH"
  },
  {
    id: 4,
    img: "images/gym/gym7.jpeg",
    text:"WEIGHT BENCH"
  },
];

export const Vip_section = [
  {
    id: 1,
    img: "images/VIP/vip1.jpeg",
    text:"Exclusive Suites"
  },
  {
    id: 2,
    img: "images/VIP/vip2.jpeg",
    text:"VIP Lounge"
  },
  {
    id: 3,
    img: "images/VIP/vip3.jpeg",
    text:"Private Retreats"
  },
  {
    id: 4,
    img: "images/VIP/vip4.jpeg",
    text:"Elite Chambers"
  },
  {
    id: 5,
    img: "images/VIP/vip5.jpeg",
    text:"Luxury Quarters"
  },
  {
    id: 6,
    img: "images/VIP/vip6.jpeg",
    text:"Prestige Haven"
  },
  {
    id: 7,
    img: "images/VIP/vip7.jpeg",
    text:"Platinum Suites"
  },

  {
    id: 8,
    img: "images/VIP/vip8.jpeg",
    text:"Celeb Sanctuaries"
  },
];

export const sports = [
  {
    id: 1,
    img: "images/sports/sport1.jpeg",
    text:"Snooker Area"
  },
  {
    id: 2,
    img: "images/sports/sport2.jpeg",
    text:"Table tennis"
  },
  {
    id: 3,
    img: "images/sports/sport3.jpeg",
    text:"Table tennis"
  },
  {
    id: 4,
    img: "images/sports/sport4.jpeg",
    text:"Tennis Arena"
  },
  {
    id: 5,
    img: "images/sports/sport5.jpeg",
    text:"Tennis Arena"
  },
  {
    id: 6,
    img: "images/sports/sport6.jpeg",
    text:"Pool area"
  },
  {
    id: 7,
    img: "images/sports/sport7.jpeg",
    text:"Swimming Area"
  },
  {
    id: 8,
    img: "images/sports/sport8.jpeg",
    text:"Swimming Pool"
  },
  {
    id: 9,
    img: "images/sports/sport9.jpeg",
    text:"Bedminton Area"
  },
];

export const managment = [ 
  {
    id: 3,
    img: "images/managment/managment.jpeg",
    text:"Team Collaboration"
  },
  {
    id: 4,
    img: "images/managment/managment5.jpeg",
    text:"Efficient Operations"
  },
  {
    id: 4,
    img: "images/managment/managments3.jpeg",
    text:"Leadership Development"
  },
  {
    id: 4,
    img: "images/managment/managment6.jpeg",
    text:"Staff Empowerment"
  },
  {
    id: 4,
    img: "images/managment/managment7.jpeg",
    text:"Strategic Planning"
  },
];
export const events = [
 
 
  // {
  //   id: 3,
  //   img: "images/managment/managment.jpeg",
  // },
  // {
  //   id: 4,
  //   img: "images/managment/managmant1.jpeg",
  // },
  
  {
    id: 6,
    img: "img/event1.jpg",
    text:"Memorable Experiences"
  },
  {
    id: 62,
    img: "img/event2.jpg",
    text:"Seamless Execution"
  },
  {
    id: 61,
    img: "img/event3.jpg",
    text:"Creative Concepts"
  },
];
export const functions = [
  {
    id: 1,
    img: "images/managment/managmant1.jpeg",
    text:"Dynamic Workouts"
  },
 
  {
    id: 3,
    img: "images/managment/managment.jpeg",
    text:"Wellness Events"
  },
  {
    id: 4,
    img: "images/managment/managmant1.jpeg",
    text:"Team Building"
  },
  // {
  //   id: 5,
  //   img: "images/managment/managmant3.jpeg",
  // },
];

import React from "react";
import { data } from "./data";
import "./card.css";
import { Link } from "react-router-dom";
const Card = () => {
  return (
    <div className="new_container">
      {data.map((item, index) => {
        return (
          <div className="team-box-2 custom-class">
            <Link to={item.link} key={item.id}>
              <img
                src="./images/board/team-shape.png"
                className="team-shape-2"
                alt="no mage"
              />
              <img
                src="./images/board/teamshape2.png"
                className="team-top"
                alt="no mage"
              />
              <img
                src="./images/board/teamshape3.png"
                className="team-half-circle"
                alt="no mage"
              />
              <div className="team-bio-2">
                <a href="/">
                  <h4 className="heading-4">{item.name}</h4>
                </a>
                <span className="post-post">{item.post}</span>
              </div>
              <div className="team-pic-2">
                <img
                  src={item.image}
                  alt="no image"
                  className="main_image_image"
                />
              </div>
              <div className="team-social-2">
                <ul className="team-social">
                  <li>
                    <a href="/">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default Card;

import React, { useLayoutEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Footer from "./components/footer/Footer";
import Home from "./pages/home/Home";
import Contact from "./pages/contact/Contact";
import "./App.css";
import "./index.css";
import BookingForm from "./pages/bookingForm/BookingForm";
import About from "./pages/about/About";
import Facility from "./pages/facility/Facility";
import Publications from "./pages/publications/Publications";
import Blogs from "./pages/blog/Blogs";
import PublicationDetail from "./pages/publications/PublicationDetail";
import SingleBlogPost from "./pages/blog/SingleBlogPost";
import Cinema from "./pagesComponents/sections/cinema/Cinema";
import VipArea from "./pagesComponents/sections/vipArea/VipArea";
import GymArea from "./pagesComponents/sections/gym/Gym";
import Events from "./pagesComponents/sections/events/Events";
import Sports from "./pagesComponents/sections/sports/Sports";
import PageNotFound from "./pages/notFound/NotFound";
import GuestRoom from "./pagesComponents/sections/guestRoom/GuestRoom";
import PersonDetailScreen from "./pages/personDetailScreen/PersonDetailScreen";
import MuhSallahudin from "./pages/personDetailScreen/pages/AhmedYarChadhar";
import CaptRetdShoaibAli from "./pages/personDetailScreen/pages/CaptRetdShoaibAli";
import AbbasMeikan from "./pages/personDetailScreen/pages/AbbasMeikan";
import ShafiWarriach from "./pages/personDetailScreen/pages/ShafiWarriach";
import Nissowana from "./pages/personDetailScreen/pages/Nissowana";
import PrivacyPolicy from "./pages/privacyPolicy/PrivacyPolicy";
import RidingForm from "./pages/bookingForm/RidingForm";
import GuestRoomForm from "./pages/bookingForm/GuestRoomForm";
import CoffeeShopForm from "./pages/bookingForm/CoffeeShopForm";
import CinemaForm from "./pages/bookingForm/CinemaForm";
import Gallery from "./pages/gallary/Gallery";
import AllEvent from "./pages/events/Event";
const App = () => {
  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };
  return (
    <div>
      <BrowserRouter>
        <Wrapper>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/booking-form" element={<BookingForm />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/all-events" element={<AllEvent/>} />
            <Route path="/board-of-governors" element={<Facility />} />
            <Route path="/blog" element={<Blogs />} />
            <Route path="/blogs/1" element={<SingleBlogPost />} />
            <Route path="/publications" element={<Publications />} />
            <Route path="/publication/1" element={<PublicationDetail />} />
            <Route path="/cinema" element={<Cinema />} />
            <Route path="/executive-area" element={<VipArea />} />
            <Route path="/gym-area" element={<GymArea />} />
            <Route path="/events" element={<Events />} />
            <Route path="/sports" element={<Sports />} />
            <Route path="/guest-room" element={<GuestRoom />} />
            <Route path="/AhmedYarChadhar" element={<PersonDetailScreen />} />
            <Route path="/MuhSallahudin" element={<MuhSallahudin />} />
            <Route
              path="/Capt-Retd-Shoaib-Ali"
              element={<CaptRetdShoaibAli />}
            />
            <Route path="/Abbas-Meikan" element={<AbbasMeikan />} />
            <Route path="/Shafi-Warriach" element={<ShafiWarriach />} />
            <Route path="/Nissowana" element={<Nissowana />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />

            <Route path="/riding-form" element={<RidingForm />} />
            <Route path="/guest-room-form" element={<GuestRoomForm />} />
            <Route path="/coffee-shop-form" element={<CoffeeShopForm />} />
            <Route path="/cinema-shop-form" element={<CinemaForm />} />


            <Route path="*" element={<PageNotFound />} />
          </Routes>
          <Footer />
        </Wrapper>
      </BrowserRouter>
    </div>
  );
};

export default App;

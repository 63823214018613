import React from "react";
import "../personDetailScreen.css";
import NavbarTwo from "../../../components/navbar/NavbarTwo";

const CaptRetdShoaibAli = () => {
  return (
    <>
      <NavbarTwo />
      <div className="profil-area section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 my-4 shadow-sm">
              <div className="profile-name-img-area text-center box-shadow2 border-top2 section-background">
                <div className="prfile-img ">
                  <img
                    src="/facility/facal4.jpeg"
                    alt="profile"
                    className="prfile-img img-responsive"
                  />
                </div>
                <div className="profile-name text-center">
                  <h1>Capt (Retd) Shoaib Ali</h1>
                  <h5 className="cd-headline letters scale">
                  Deputy Commissioner Sargodha / President, Sargodha Gymkhana Club
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-md-6 my-4 shadow-sm">
              <div className="profile box-shadow2 border-top2 section-background">
                <div className="section-title">
                  <h3>Profile</h3>
                </div>
                <div className="profile-info table-responsive">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <i className="fa fa-user"></i>
                        </td>
                        <td>
                          <span>Name</span>
                        </td>
                        <td>
                          <b>:</b>
                        </td>
                        <td>Capt (Retd) Shoaib Ali</td>
                      </tr>
                      <tr>
                        <td>
                          <i className="fa fa-calendar"></i>
                        </td>
                        <td>
                          <span>Date of Birth</span>
                        </td>
                        <td>
                          <b>:</b>
                        </td>
                        <td>10/02/1993</td>
                      </tr>
                      <tr>
                        <td>
                          <i className="fa fa-map-marker-alt"></i>
                        </td>
                        <td>
                          <span>Address</span>
                        </td>
                        <td>
                          <b>:</b>
                        </td>
                        <td>Shop # 13-14 Gulbergcity</td>
                      </tr>
                      <tr>
                        <td>
                          <i className="fab fa-whatsapp-square"></i>
                        </td>
                        <td>
                          <span>Whatsapp</span>
                        </td>
                        <td>
                          <b>:</b>
                        </td>
                        <td>000000000</td>
                      </tr>
                      <tr>
                        <td>
                          <i className="fa fa-envelope"></i>
                        </td>
                        <td>
                          <span>Email</span>
                        </td>
                        <td>
                          {" "}
                          <b>:</b>
                        </td>
                        <td>example@gmail.com</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="objective-area box-shadow2 border-top2 section-background color">
                <div className="section-title">
                  <h3>Message</h3>
                </div>
                <div className="objective-content">
                  <p>
                    Currently serving as the Deputy Commissioner of Sargodha,
                    Capt (Retd) Shoaib Ali holds a position of great
                    responsibility in the district. His role involves managing
                    various administrative and governance matters, ensuring the
                    smooth functioning of the district's governmental affairs.
                    His leadership and problem-solving skills have undoubtedly
                    played a pivotal role in the development and progress of
                    Sargodha under his watch.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CaptRetdShoaibAli;

import React from "react";
import "./slider.css";
const Slider = ({about}) => {
  return (
    <section className="bg_image_3">
    <div className="content">
      {/* <h2>Contact Us</h2> */}
      <h2>{about}</h2>
    
    </div>
  </section>
  );
};

export default Slider;

import React from "react";
import "./about.css";
import NavbarTwo from "../../components/navbar/NavbarTwo";
import SportsMartialArtsIcon from "@mui/icons-material/SportsMartialArts";
import LocalDiningIcon from "@mui/icons-material/LocalDining";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import BiotechIcon from "@mui/icons-material/Biotech";
import KingBedIcon from "@mui/icons-material/KingBed";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import Testimonial from "../../components/testimonials/Testimonial";
import { useDocumentTitle } from "../../useDocumentTitle";
const About = () => {
  useDocumentTitle("About us")
  return (
    <>
      <NavbarTwo />

      <div className="breadcrumbs" data-stellar-background-ratio="0.5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12 mx-auto">
              <div className="breadcrumbs-content left text-center">
                <h1 className="page-title">About Us</h1>
                <p>
                The Gymkhana Club is a prestigious institution offering sports, fitness, and social amenities to its members. It fosters a strong sense of community, hosting various events and gatherings. Members enjoy leisure and networking opportunities in a welcoming environment.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="Features section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12">
              <div className="feature-right wow fadeInUp" data-wow-delay=".3s">
                <div className="watch-inner">
                  <div className="video-head wow zoomIn" data-wow-delay="0.4s">
                    <a
                      href="https://www.youtube.com/watch?v=BqI0Q7e4kbk"
                      className="glightbox video"
                    >
                      <i className="lni lni-play"></i>
                    </a>
                    {/* <img src="https://jtechit.com.pk/sandbox/gymkhanaclub/img/feature-three-img-2.jpg" alt="" /> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="feature-content">
                <div className="title">
                  <span className="wow fadeInRight" data-wow-delay=".3s">
                    Our Introduction
                  </span>
                  <h3 className="wow fadeInRight" data-wow-delay=".5s">
                    Where real <br /> Life begins
                  </h3>
                </div>
                <div className="feature-item wow fadeInUp" data-wow-delay=".5s">
                  <div className="feature-thumb_1">
                    <i className="lni lni-bar-chart"></i>
                  </div>
                  <div className="banner-content">
                    <h2 className="title">Management Area</h2>
                    <p>
                      Management in a gymkhana involves overseeing various
                      aspects of the facility to ensure smooth operations and
                      customer satisfaction.
                    </p>
                  </div>
                </div>
                <div className="feature-item wow fadeInUp" data-wow-delay=".6s">
                  <div className="feature-thumb_2">
                    <i className="lni lni-checkmark-circle"></i>
                  </div>
                  <div className="banner-content">
                    <h2 className="title">Sports Area</h2>
                    <p>
                      The sports area in the gymkhana is a bustling hub of
                      athletic activity, where members and athletes come
                      together to hone their skills and compete in various
                      sports
                    </p>
                  </div>
                </div>
                <div className="feature-item wow fadeInUp" data-wow-delay=".7s">
                  <div className="feature-thumb_3">
                    <i className="lni lni-support"></i>
                  </div>
                  <div className="banner-content">
                    <h2 className="title">Long Time Support</h2>
                    <p>
                      Strategy and research play a pivotal role in the success
                      of a gymkhana event. A well-thought-out strategy helps
                      participants maximize their performance,
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services section">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2 col-12">
              <div className="section-title">
                {/* <span className="wow text-center fadeInDown" data-wow-delay=".2s">
                  What We Offer You
                </span> */}

                <h1 className="wow  text-center fadeInUp" data-wow-delay=".4s">
                  <b> Our Services</b>
                </h1>
                <p className="wow text-center  fadeInUp" data-wow-delay=".6s">
                  Management in a gymkhana involves overseeing various aspects
                  of the facility to ensure smooth operations and customer
                  satisfaction.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-service wow fadeInUp" data-wow-delay=".2s">
                <div className="serial">
                  <span>
                    {/* <i className="lni lni-code"></i> */}
                    <LocalDiningIcon className="icon_about" />
                  </span>
                </div>
                <h3>
                  <a href="service-single.html">Food & Beverages</a>
                </h3>
                <p>
                  Food and beverages are essential elements of human sustenance,
                  providing nourishment and enjoyment to people around the
                  world.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-service wow fadeInUp" data-wow-delay=".3s">
                <div className="serial">
                  <span>
                    {/* <i className="lni lni-vector"></i> */}
                    <SportsMartialArtsIcon className="icon_about" />
                  </span>
                </div>
                <h3>
                  <a href="service-single.html">All Sports</a>
                </h3>
                <p>
                  The gymkhana is a vibrant hub of sports and recreation,
                  offering a wide array of activities to cater to all
                  enthusiasts.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-service wow fadeInUp" data-wow-delay=".4s">
                <div className="serial">
                  <span>
                    {/* <i className="lni lni-ux"></i> */}
                    <LocalLibraryIcon className="icon_about" />
                  </span>
                </div>
                <h3>
                  <a href="service-single.html">Gymkhana Library</a>
                </h3>
                <p>
                  It combines the love of literature with the pursuit of
                  physical well-being, creating a unique  
                  experience.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-service wow fadeInUp" data-wow-delay=".5s">
                <div className="serial">
                  <span>
                    {/* <i className="lni lni-keyword-research"></i> */}
                    <BiotechIcon className="icon_about" />
                  </span>
                </div>
                <h3>
                  <a href="service-single.html">Strategy & Research</a>
                </h3>
                <p>
                  Need A Project Completed By An Expert? Let’s Go! Access A
                  Human Resources Consultant To Answer Questions
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-service wow fadeInUp" data-wow-delay=".6s">
                <div className="serial">
                  <span>
                    {/* <i className="lni lni-seo"></i> */}
                    <KingBedIcon className="icon_about" />
                  </span>
                </div>
                <h3>
                  <a href="">Guest Room</a>
                </h3>
                <p>
                  All guest rooms at Gymkhana offer a blend of luxury and
                  comfort, providing a relaxing retreat for visitors.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-service wow fadeInUp" data-wow-delay=".7s">
                <div className="serial">
                  <span>
                    {/* <i className="lni lni-bar-chart"></i> */}
                    <CropSquareIcon className="icon_about" />
                  </span>
                </div>
                <h3>
                  <a href="service-single.html">Banquet Room</a>
                </h3>
                <p>
                  The banquet room at Gymkhana exudes an elegant and upscale
                  ambiance, making it the perfect setting for special events
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="client-logo-section">
        <div className="container">
          <div className="client-logo-wrapper">
            <div className="client-logo-carousel d-flex align-items-center justify-content-between">
              <div className="client-logo">
                <img src="assets/images/client1.png" alt="" />
              </div>
              <div className="client-logo">
                <img src="assets/images/client2.png" alt="" />
              </div>
              <div className="client-logo">
                <img src="assets/images/client3.png" alt="" />
              </div>
              <div className="client-logo">
                <img src="assets/images/client4.png" alt="" />
              </div>
              <div className="client-logo">
                <img src="assets/images/client5.png" alt="" />
              </div>
              <div className="client-logo">
                <img src="assets/images/client2.png" alt="" />
              </div>
              <div className="client-logo">
                <img src="assets/images/client3.png" alt="" />
              </div>
              <div className="client-logo">
                <img src="assets/images/client4.png" alt="" />
              </div>
              <div className="client-logo">
                <img src="assets/images/client5.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Testimonial />
    </>
  );
};

export default About;

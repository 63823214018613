import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Timelapse } from "@mui/icons-material";
import SmartButtonIcon from "@mui/icons-material/SmartButton";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import SportsFootballIcon from "@mui/icons-material/SportsFootball";
import "./gallerySection.css";
import {
  sports,
  Vip_section,
  gym,
  managment,
  functions,
  events,
} from "./dummy";
const GallerySection = () => {
  const [toggleState, setToggleState] = useState(1);
  const toggleTabs = (index) => {
    setToggleState(index);
  };
  return (
    <>
      <div className="gallary_topBar">
        <ul>
          <Link>
            <li
              className={
                toggleState === 1
                  ? "gallary_topBar_firstItem"
                  : "gallary_topBar_non_active"
              }
              onClick={() => toggleTabs(1)}
            >
              <FitnessCenterIcon className="gallary_topBar_icon" />
              Gym
            </li>
          </Link>
          <Link>
            <li
              onClick={() => toggleTabs(2)}
              className={
                toggleState === 2
                  ? "gallary_topBar_firstItem"
                  : "gallary_topBar_non_active"
              }
            >
              <Timelapse className="gallary_topBar_icon" />
              VIP
            </li>
          </Link>
          <Link>
            <li
              onClick={() => toggleTabs(7)}
              className={
                toggleState === 7
                  ? "gallary_topBar_firstItem"
                  : "gallary_topBar_non_active"
              }
            >
              <SmartButtonIcon className="gallary_topBar_icon" />
              Functions
            </li>
          </Link>
          <Link>
            <li
              onClick={() => toggleTabs(3)}
              className={
                toggleState === 3
                  ? "gallary_topBar_firstItem"
                  : "gallary_topBar_non_active"
              }
            >
              <SportsFootballIcon className="gallary_topBar_icon" />
              Sports
            </li>
          </Link>
          <Link>
            <li
              onClick={() => toggleTabs(4)}
              className={
                toggleState === 4
                  ? "gallary_topBar_firstItem"
                  : "gallary_topBar_non_active"
              }
            >
              <ManageAccountsIcon className="gallary_topBar_icon" />
              Managment
            </li>
          </Link>
          <Link>
            <li
              onClick={() => toggleTabs(6)}
              className={
                toggleState === 6
                  ? "gallary_topBar_firstItem"
                  : "gallary_topBar_non_active"
              }
            >
              <EventAvailableIcon className="gallary_topBar_icon" />
              Events
            </li>
          </Link>
        </ul>
      </div>
      <div className="gallary_page_component_container">
        <div className="animinities_heading">
          {/* <h1 className="features_heading_main">GALLERY</h1> */}
        </div>
        <div className="gallary_page_component_wrapper ">
          <div
            className={
              toggleState === 1
                ? "gallary_page_component_image_wrapper"
                : "inActive_image_wrapper"
            }
          >
            {gym.map((image) => (
              <div className="gallary_page_component_image_container">
                <img
                  key={image.id}
                  src={image.img}
                  alt=""
                  className="gallary_page_component_image"
                />
                <h4 className="overlaytext">{image.text}</h4>
              </div>
            ))}
          </div>
          <div
            className={
              toggleState === 2
                ? "gallary_page_component_image_wrapper"
                : "inActive_image_wrapper"
            }
          >
            {Vip_section.map((image) => (
              <div className="gallary_page_component_image_container">
                <img
                  key={image.id}
                  src={image.img}
                  alt=""
                  className="gallary_page_component_image"
                />
                <h4 className="overlaytext">{image.text}</h4>
              </div>
            ))}
          </div>
          <div
            className={
              toggleState === 3
                ? "gallary_page_component_image_wrapper"
                : "inActive_image_wrapper"
            }
          >
            {sports.map((image) => (
              <div className="gallary_page_component_image_container">
                <img
                  key={image.id}
                  src={image.img}
                  alt=""
                  className="gallary_page_component_image"
                />
                <h4 className="overlaytext">{image.text}</h4>
              </div>
            ))}
          </div>
          <div
            className={
              toggleState === 4
                ? "gallary_page_component_image_wrapper"
                : "inActive_image_wrapper"
            }
          >
            {managment.map((image) => (
              <div className="gallary_page_component_image_container">
                <img
                  key={image.id}
                  src={image.img}
                  alt=""
                  className="gallary_page_component_image"
                />
                 
                <h4 className="overlaytext">{image.text}</h4>
              </div>
            ))}
          </div>
          <div
            className={
              toggleState === 7
                ? "gallary_page_component_image_wrapper"
                : "inActive_image_wrapper"
            }
          >
            {functions.map((image) => (
              <div className="gallary_page_component_image_container">
                <img
                  key={image.id}
                  src={image.img}
                  alt=""
                  className="gallary_page_component_image"
                />
                <h4 className="overlaytext">{image.text}</h4>
              </div>
            ))}
          </div>
          <div
            className={
              toggleState === 6
                ? "gallary_page_component_image_wrapper"
                : "inActive_image_wrapper"
            }
          >
            {events.map((image) => (
              <div className="gallary_page_component_image_container">
                <img
                  key={image.id}
                  src={image.img}
                  alt=""
                  className="gallary_page_component_image"
                />
                <h4 className="overlaytext">{image.text}</h4>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default GallerySection;

export const dataDetails=[
    
    {
        id:"1",
        image:"",
        name:"Mohammad Mohsin Sallahudin",
        position:"Additional Deputy Commissioner (Revenue) / Secretary Finance, Sargodha Gymkhana Club",
        desc:"As the President of Sargodha Gymkhana Club, Captain Retired Shoaib Ali is a driving force behind the promotion of sports and recreational activities in the region. His commitment to fostering a healthy and active community is evident through his leadership in the club. The Gymkhana Club, under his stewardship, likely thrives as a hub for various sports, social events, and cultural activities, bringing people together and creating a sense of belonging within the community."

    },
    {
        id:"2",
        desc:"In his role as Secretary Finance at the Sargodha Gymkhana Club, Mohammad Mohsin Sallahudin showcases his financial acumen and organizational skills. The Sargodha Gymkhana Club is a prominent social and recreational hub in the region, and his role involves managing and maintaining the club's financial operations. He works diligently to ensure the club's fiscal health, contributing to the success and sustainability of this esteemed institution.",
        name:"Mohammad Mohsin Sallahudin",
        position:"Secretary, Sargodha Gymkhana Club",
        image:""

    },
    {
        id:"3",
        image:"facility/facality1.jfif",
        name:"Ahmed Yar Chadhar",
        position:"Secretary, Sargodha Gymkhana Club  ",
        desc:"As Secretary, Ahmed Yar Chadhar is responsible for overseeing the day-to-day affairs of the Sargodha Gymkhana Club. His dedication and leadership are instrumental in ensuring that the club provides top-notch facilities for various sporting activities, social gatherings, and recreational events. Under his guidance, the club has flourished and become a hub for both sports enthusiasts and those seeking a friendly and welcoming atmosphere in Sargodha."

    },
    {
        id:"4",
        image:"facility/facality4.jpeg",
        name:"Sardar Fakhar Abbas Meikan",
        position:"Member Board of Governors",
        desc:"As a Member of the Board of Governors, Sardar Fakhar Abbas Meikan plays a pivotal role in guiding the organization towards achieving its objectives and ensuring its long-term sustainability. His contributions, both in terms of governance and strategy, have been instrumental in the success and growth of the organization."

    },
    {
        id:"5",
        image:"facility/facality4.jpeg",
        name:"Shahraiz Shafi Warriach",
        position:"Member Board of Governors",
        desc:"As a Member of the Board of Governors, Shahraiz Shafi Warriach plays a pivotal role in guiding the organization towards achieving its objectives and ensuring its long-term sustainability. His contributions, both in terms of governance and strategy, have been instrumental in the success and growth of the organization."

    },
    {
        id:"6",
        image:"facility/facality4.jpeg",
        name:"Muhammad Shoaib Nissowana",
        position:"Additional Deputy Commissioner (Finance and Planning ) / member, Board of Governors",
        desc:"In his role as Additional Deputy Commissioner, Mr. Nissowana plays a pivotal role in shaping and implementing strategic financial policies and plans for the jurisdiction he serves. He oversees the allocation of financial resources, ensuring that government funds are efficiently and effectively utilized to meet the needs and priorities of the community.."

    },
]
import React from "react";
import Events from "../../components/events/Events";
import NavbarTwo from "../../components/navbar/NavbarTwo";

const AllEvent = () => {
  return (
    <>
    <NavbarTwo/>
      <Events  eventsPage="eventsPage" />

    </>
  );
};

export default AllEvent;

import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div className="header-img">
      <div className="rgba_img"></div>
      <header>
        <div className="container">
          <div className="row top-header">
            <div className="col-lg-8">
              <ul className="list-unstyled d-flex">
                <li className="head">
                  <i className="fas fa-envelope h-icon"></i> 
                  Sargodhagymkhanaclub@gmail.com
                </li>
                <li className="head">
                  <i className="fas fa-phone h-icon"></i> (048) 3768504
                </li>
              </ul>
            </div>
            <div className="col-lg-4">
              <ul className="list-unstyled d-flex text-decoration-none top-menu">
                <li className="head">
                  <i className="fas fa-clock h-icon"></i> Open hours: Mon - Sunday 7:00
                  am - 11:30 PM
                </li>
              </ul>
            </div>
          </div>
          <div className="justify-content-center header_new header-transparent">
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <div className="container">
                <Link className="navbar-brand logo" to="/">
                  <img src="img/logo.png" />
                </Link>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav  me-auto mb-2 mb-lg-0 ms-auto">
                    <li className="nav-item">
                      <Link to="/">
                        <a className="nav-link" href="#">
                          Home
                        </a>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/gallery">
                        <a className="nav-link" href="#">
                          Gallery
                        </a>
                      </Link>
                    </li>
                    
                    <li className="nav-item">
                      <Link to="booking-form">
                        <a className="nav-link" href="">
                          Booking
                        </a>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/about">
                        <a className="nav-link" href="">
                          About
                        </a>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/board-of-governors">
                        <a className="nav-link" href="#">
                        Board
                         </a>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/all-events">
                        <a className="nav-link" href="#">
                        Events
                         </a>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/contact">
                        <a className="nav-link" href="#">
                          Contact
                        </a>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <form className="myfrm d-flex">
                <div className="srch-ic d-flex">
                  <i className="fas fa-search s-icon"></i>
                </div>
                <div className="main-menu-two__btn-box">
                  <a className="thm-btn main-menu-two__btn" href="/">
                    My Gymkhana
                  </a>
                </div>
              </form>
            </nav>
          </div>
        </div>
      </header>

      <div className="container">
        <div
          id="carouselExampleControls"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="row">
                <div className="col-lg-6 col-sm-12 be-f">
                  <div className=" caro-t text-start animated bounceInDown">
                    <p className="fs-5 pt-3 ">Sargodha Gymkhana Club</p>
                    <h2 className="font pb-5 display-3 fw-semibold text-white">
                      Sargodha Gymkhana Club offers a wide range of
                      <br /> sports facilities
                    </h2>
                    <div className="d-flex justify-content-between">
                      <div className="btn-box">
                        <Link to="/contact" className="thm-btn btn  p-3 ps-5 pe-5 ">
                          Contact Us
                        </Link>
                      </div>
                      <div className="ms-5">
                        <img src="img/arr.png" className="kk-r" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="row">
                <div className="col-lg-6 col-sm-12  be-f">
                  <div className=" caro-t text-start animated bounceInDown">
                    <p className="fs-5 pt-3 ">Sargodha Gymkhana Club</p>
                    <h2 className="font pb-5 display-3 fw-semibold text-white">
                      The only official club in sarogdha providing
                      <br /> the quality food
                    </h2>
                    <div className="d-flex justify-content-between">
                      <div className="btn-box">
                        <a href="#" className="thm-btn btn  p-3 ps-5 pe-5 ">
                          Contact Us
                        </a>
                      </div>
                      <div className="ms-5">
                        <img src="img/arr.png" className="kk-r" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="row">
                <div className="col-lg-6 col-sm-12  be-f">
                  <div className=" caro-t text-start animated bounceInDown">
                    <p className="fs-5 pt-3 ">Sargodha Gymkhana Club</p>
                    <h2 className="font pb-5 display-3 fw-semibold text-white">
                      Sargodha Gymkhana Club Rooms are beautifully
                      <br /> and artistically decorated
                    </h2>
                    <div className="d-flex justify-content-between">
                      <div className="btn-box">
                        <a
                          href="/contact"
                          className="thm-btn btn  p-3 ps-5 pe-5 "
                        >
                          Contact Us
                        </a>
                      </div>
                      <div className="ms-5">
                        <img src="img/arr.png" className="kk-r" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;

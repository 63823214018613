import React from "react";
import NavbarTwo from "../../../components/navbar/NavbarTwo";
import ButtonComponent from "../../../components/buttonComponent/ButtonComponent";
const GuestRoom = () => {
  return (
    <>
      <NavbarTwo />
      <div className="cinema_container">
        <div className="cinema_wrapper">
          <img
            src="/img/r1.jpg"
            alt=""
            className="picture_images"
          />
        </div>

          <div className="once_description">
            <h1>
            Luxurious Retreat: Guest Rooms at the Gymkhana
            </h1>
            <p>
              A guest room in a gymkhana, also known as a club, is a welcoming
              and often luxurious space designed to provide comfort and
              accommodation for members and their guests. Gymkhanas are
              typically private clubs or social establishments that offer a
              variety of recreational and social activities, and they often have
              guest rooms to cater to members who may be visiting from out of
              town or simply want a convenient place to stay after a late-night
              event.
              <br />
              These guest rooms are designed to provide a home away from home
              experience, offering a comfortable and cozy ambiance. They are
              typically equipped with all the amenities that one might expect in
              a well-appointed hotel room. Here are some key features you might
              find in a guest room at a gymkhana:
              <br />
            </p>
            <ButtonComponent title="Book Now"/>

            <img
                  src="/img/r2.jpg"
              alt=""
              className="image_sub"
            />

            <p>
              The keyhole race is a test of precision, as riders must guide
              their horses into a tight keyhole-shaped pattern and then back out
              again as quickly as possible. Stake racing involves circling a
              series of stakes in a specific order, showcasing the horse's
              agility and the rider's ability to guide them accurately.
              <br />
              Personalized service: VIPs may receive more attention and
              personalized assistance from staff. Reserved seating: VIP areas
              often have reserved seating, which can be advantageous for events
              or busy periods. Catering services: VIP areas may offer exclusive
              food and beverage options. Access control: These areas might have
              restricted access to ensure only authorized individuals can enter.
              <br />
              <br />
             The heart of any guest room is, of course, the bedroom.
              Gymkhana guest rooms typically offer a comfortable bed with
              high-quality linens, ensuring a restful night's sleep. Some guest
              rooms may have single beds, while others offer double or even
              larger options to accommodate different needs. En Suite  
              Most gymkhana guest rooms come with a private bathroom that
              includes modern fixtures and complimentary toiletries. It ensures
              that guests have the convenience and privacy they desire.
            </p>
            <img
             src="/img/r3.jpg"
              alt=""
              className="image_sub"
            />
          </div>
      </div>
    </>
  );
};

export default GuestRoom;
